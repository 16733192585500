import { Avatar } from '@progress/kendo-react-layout';

const CompetencyModelStatusLabel = (props) => {
  return (
    <Avatar
      type="text"
      rounded="medium"
      themeColor={
        props.status === 'D' ? 'dark' : props.status === 'A' ? 'success' : 'error'
      }
      style={{
        width: 'auto',
        height: 'auto',
        padding: '0px 15px',
        minWidth:'66px'
      }}
    >
      <small>
        {props.status === 'D' ? 'Draft' : props.status === 'A' ? 'Active' : 'Closed'}
      </small>
    </Avatar>
  );
};

export default CompetencyModelStatusLabel;