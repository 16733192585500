import React, { useEffect } from 'react';
import { CKEditor } from 'ckeditor4-react';

const HtmlEditor = (props) => {
  const cfg = {
    enterMode: 2,
    height: props.height || '80px',
    contentsCss: '/editor.css',
    autoGrow_minHeight: '60px',
    autoGrow_maxHeight: '300px',
    removePlugins: 'elementspath',
    resize_enabled: false,
    toolbar: [
      ['Source'],
      ['Bold', 'Italic', 'Link'],
      ['Undo', 'Redo'],
    ],
    readOnly: props.disabled,
  };

  // Gestione del cambiamento
  const onChange = (event) => {
    props.onChange({ value: event.editor.getData() });
    console.log('onChange>>>>', event.editor.getData());
  };

  // Importa lo script personalizzato di CKEditor
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/ckeditor/ckeditor.js';
    script.async = true;
    script.onload = () => {
      console.log('CKEditor script loaded successfully');
    };
    script.onerror = () => {
      console.error('Failed to load CKEditor script');
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <CKEditor
      {...props}
      config={cfg}
      initData={props.value}
      onChange={onChange}
      events={{
        change: onChange,
      }}
      onInstanceReady={() => {
        console.log('CKEditor instance ready');
      }}
    />
  );
};

export default HtmlEditor;
