import { createPortal } from 'react-dom';

const LoadingPanel = (props) => {
  return (
    <>
      {createPortal(
        <div
          className="k-loading-mask"
          style={{ zIndex: 99999999, position: 'fixed' }}
        >
          <span className="k-loading-text">Loading</span>
          <div className="k-loading-image"></div>
          <div className="k-loading-color"></div>
        </div>,
        document.getElementById('loader')
      )}
    </>
  );
};

export default LoadingPanel;
