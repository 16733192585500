import { authFetch } from "api";
import { COMPETENCY_API_BASE_ROUTE } from 'settings/api-settings';

export const createCompetency = async (competency) => {
  const url = COMPETENCY_API_BASE_ROUTE
  const response = await authFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(competency),
  });
  if (response.status === 201) {
    return await response.json();
  }

  if (response.status === 204) {
    return {};
  }
  throw `Unhandled status code ${response.status} for createBehaviorMarker`;
};

export const updateCompetency = async (competency) => {
  const url = COMPETENCY_API_BASE_ROUTE + '/' + competency.competencyId + '/resource';
  const response = await authFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(competency),
  });
};



export const reorderCompetency = async (competencyId, sort) => {
  const url = COMPETENCY_API_BASE_ROUTE + '/' + competencyId + '/sort';
  const response = await authFetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({sort}),
  });

  if (response.status === 200) {
    return await response.json();
  }

  throw `Unhandled status code ${response.status} for reorderCompetency`;
};


export const deleteCompetency = async (competencyId) => {
  const url = COMPETENCY_API_BASE_ROUTE + '/' + competencyId

  const response = await authFetch(url, {
    method: 'DELETE'
  });

  if(!response.ok) {
    throw response;
  }

  return {}
};

