import ProjectStatusLabel from '../../ProjectStatusLabel';

const StatusGridColumn = (props) => {
  return (
    <td style={{ textOverflow: 'unset' }}>
      <ProjectStatusLabel status={props.dataItem.status} />
    </td>
  );
};

export default StatusGridColumn;