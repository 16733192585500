import { CLIENTS_API_BASE_ROUTE } from 'settings/api-settings';
import { authFetch } from "api";

export const getClients = async (filters) => {
  let qsFilters = "";
  if(filters){
    qsFilters = new URLSearchParams(filters).toString();
  }

  const response = await authFetch(CLIENTS_API_BASE_ROUTE + '?' + qsFilters);
  if(response.ok) {
    const clients = await response.json();
    return clients.map((client) => ({
      ...client,
      createdDate: client.createdDate && new Date(client.createdDate),
      modifiedDate: client.modifiedDate && client.modifiedDate && new Date(client.modifiedDate),
    }));

  }
  else if(response.status === 401) {
    throw Error('REDIRECT_TO_LOGIN');
  }
  else if(response.status >= 500) {
    throw Error('Something went wrong loading the clients.');
  }
};

export const getClient = async (clientId) => {
  const url = CLIENTS_API_BASE_ROUTE + '/' + clientId;
  const response = await authFetch(url);
  const client = await response.json();
  return client;
};

export const createClient = async (clientCreateData) => {
  // await new Promise(res => setTimeout(res, 20000));
  const response = await authFetch(CLIENTS_API_BASE_ROUTE, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(clientCreateData),
  });
  if (response.status === 201) {
    return await response.json();
  }
  throw `Unhandled status code ${response.status} for createClient`;
};

export const updateClient = async (clientId, clientEditData) => {
  const url = CLIENTS_API_BASE_ROUTE + '/' + clientId;
  const response = await authFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(clientEditData),
  });
};


export const deleteClient = async (clientId) => {
  const response = await authFetch(CLIENTS_API_BASE_ROUTE + '/' + clientId, {
    method: 'DELETE'
  });

  if(!response.ok) {
    throw response;
  }
};