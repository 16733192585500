import {
  getClientOptions,
  getCompetencyModelOptions,
  getLanguageOptions,
  getOfficeOptions,
} from 'api/dropdown-values';
import { createProject } from 'api/projects';
import ProjectForm from 'components/projects/ProjectForm';
import LoadingPanel from 'components/shared/LoadingPanel';
import useAzureADAccessToken from 'hooks/use-azure-ad-access-token';
import { useCallback, useEffect, useState } from 'react';

const INITIAL_VALUES = {
  projectName: '',
  projectDescription: '',
  client: null,
  competencyModel: null,
  persons: [],
  //officeInCharge: null,
  language: null,
  status:0,
  customMessage:''
};

const NewProjectForm = (props) => {
  console.log('NewProjectForm component is running...');
  const [resources, setResources] = useState(null);
  const [loadingResources, setLoadingResources] = useState(true);

  const accessToken = useAzureADAccessToken();

  const fetchResources = useCallback(async () => {
    const resources = await Promise.all([
      getCompetencyModelOptions(),
      getLanguageOptions(),
      getClientOptions(),
      //getOfficeOptions(accessToken),
    ]);
    setResources({
      competencyModels: resources[0],
      languages: resources[1],
      clients: resources[2],
      //offices: resources[3],
    });
    setLoadingResources(false);
  }, [accessToken]);

  useEffect(() => {
    if (!!accessToken) {
      fetchResources();
    }
  }, [accessToken, fetchResources]);

  const submitClickHandler = async (submittedProject) => {
    props.onCreating?.();

    try {
      const createdProject = await createProject(submittedProject);
      props.onCreated?.(createdProject);
    } catch (errorResponse) {
      console.error(errorResponse);
      props.onCreationFailed?.(errorResponse);
    }
  };

  const cancelClickHandler = () => {
    props.onCreationCancel?.();
  };

  return loadingResources ? (
    <LoadingPanel />
  ) : (
    <ProjectForm
      title="Create Project"
      initialValues={INITIAL_VALUES}
      onSubmit={submitClickHandler}
      onCancel={cancelClickHandler}
      {...resources}
    />
  );
};

export default NewProjectForm;
