import { ADD_RESOURCE, UPDATE_RESOURCE, REPLACE_RESOURCES } from 'actions/resource-grid-actions';

const resourceGridReducer = (resources = [], action) => {
  switch (action.type) {
    case REPLACE_RESOURCES:
      return action.resources;
    case ADD_RESOURCE:
      return [...resources, action.resource];
    case UPDATE_RESOURCE:
      const resourcesCopy = [...resources];
      const modifiedResourceIndex = resourcesCopy.findIndex(
        (resource) => {return resource.resourceId === action.resourceId}
      );
      resourcesCopy[modifiedResourceIndex] = {
        ...resourcesCopy[modifiedResourceIndex],
        ...action.resourceUpdateData,
      };

      return resourcesCopy;
    default:
      return resources;
  }
};

export default resourceGridReducer;
