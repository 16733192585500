import { authFetch } from "api";
import { PERSONS_API_BASE_ROUTE, PERSONS_ANONYMIZE_API_BASE_ROUTE } from 'settings/api-settings';
import { getAccessToken } from 'utils/utils';


export const getPersons = async () => {
  //const at = await getAccessToken()

  const response = await authFetch(PERSONS_API_BASE_ROUTE);
  if(response.ok) {
    const persons = await response.json();
    return persons.map((person) => ({
      ...person,
      createdDate: new Date(person.createdDate),
      modifiedDate: person.modifiedDate && new Date(person.modifiedDate),
    }));
  }
  else if(response.status === 401) {
    throw Error('REDIRECT_TO_LOGIN');
  }
  else if(response.status >= 500) {
    throw Error('Something went wrong loading the persons.');
  }
};

export const getPerson = async (personId) => {
  const url = PERSONS_API_BASE_ROUTE + '/' + personId;
  const response = await authFetch(url);
  const person = await response.json();
  return person;
};

export const createPerson = async (personCreateData) => {
  // await new Promise(res => setTimeout(res, 20000));
  const response = await authFetch(PERSONS_API_BASE_ROUTE, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(personCreateData),
  });
  if (response.status === 201) {
    return await response.json();
  }

  if (response.status === 422) {
    throw await response.text();
  }

  if(!response.ok) {
    throw response;
  }
};

export const updatePerson = async (personId, personEditData) => {
  const url = PERSONS_API_BASE_ROUTE + '/' + personId;
  const response = await authFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(personEditData),
  });

  if(!response.ok) {
    throw response;
  }
};


export const deletePerson = async (personId) => {

  const response = await authFetch(PERSONS_API_BASE_ROUTE + '/' + personId, {
    method: 'DELETE'
  });

  if(!response.ok) {
    throw response;
  }
};



export const cancelRequestAnonymize = async (personId) => {
 
  const response = await authFetch(PERSONS_ANONYMIZE_API_BASE_ROUTE + '/' + personId + '/anonymization', {
    method: 'DELETE'
  });

  if(!response.ok) {
    throw response;
  }
};


export const requestAnonymize = async (personId) => {

  console.log('requestAnonymize',personId, PERSONS_API_BASE_ROUTE + '/' + personId + '/anonymization')
  debugger;
  const response = await authFetch(PERSONS_ANONYMIZE_API_BASE_ROUTE + '/' + personId + '/anonymization', {
    method: 'POST'
  });

  if(!response.ok) {
    throw response;
  }
};

