import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import ClientGrid from 'components/client/ClientGrid';
import LoadingPanel from 'components/shared/LoadingPanel';
import { useCallback, useEffect, useReducer, useState, useContext } from 'react';
import { DEFAULT_PAGE_SIZE } from 'settings/grid-settings';
import {
    StackLayout,
} from '@progress/kendo-react-layout';
import { getClients } from 'api/clients';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { ADD_CLIENT_ROUTE, EDIT_CLIENT_ROUTE, LIST_CLIENT_ROUTE } from 'routes';
//import { getClients } from "api";

import UserInfoContext from 'stores/user-context';

import clientsReducer from 'reducers/client-grid-reducer';
import {
    createAddClientAction,
    createUpdateClientAction,
    createReplaceClientAction,
} from 'action-creators/client-grid-action-creators';
import useNotifications from 'hooks/use-notifications';
import NewClientForm from 'components/client/NewClientForm';
import EditClientForm from 'components/client/EditClientForm';

import { createErrorNotification } from 'utils/notifications-creator';
import PageTitle from 'components/layout/PageTitle';
import RightDrawer from 'components/layout/RightDrawer';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { getOffices } from 'api/offices';

// TODO: move into a utils file
const paginate = (items, skip, take) => {
    return items.slice(skip, take + skip);
};

const initialPageState = {
    skip: 0,
    take: DEFAULT_PAGE_SIZE,
};

const ClientPage = (props) => {
    const userInfo = useContext(UserInfoContext);
    const [loading, setLoading] = useState(true);
    const [clients, dispatchClientsAction] = useReducer(clientsReducer, []);
    const [page, setPage] = useState(initialPageState);
    const [clientsData, setClientsData] = useState([]);
    const [offices, setOffices] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const history = useHistory();
    const notifications = useCallback(useNotifications, []); //();

    const fetchClients = useCallback(async () => {
        console.log('fetching clients...');
        setLoading(true);
        try {
          const clients = await getClients();
          dispatchClientsAction(createReplaceClientAction(clients));
        } catch (err) {
          notifications.push(createErrorNotification(err.message));
        } finally {
          setLoading(false);
        }
      }, [notifications,refresh]);

      const fetchOffices = useCallback(async () => {
        console.log('fetching offices...');
        setLoading(true);
        try {
          let offices = await getOffices();
        
          if(!userInfo.isSuperAdmin){
            offices = offices.filter(o => (o.officeId == userInfo.officeId))
          }

          setOffices(offices)
        } catch (err) {
            console.error(err.message);
        } finally {
          setLoading(false);
        }
      }, []);


    const pageChangeHandler = (e) => {
        setPage(e.page);
    };

    const clientAddedHandler = (client) => {
        dispatchClientsAction(createAddClientAction(client));
        setRefresh(refresh + 1)
    };

    const clientUpdatedHandler = (clientId, submittedFormData) => {
        const updateAction = createUpdateClientAction(clientId, submittedFormData);
        dispatchClientsAction(updateAction);

        setRefresh(refresh + 1)
    };

    const addButtonClickHandler = () => {
        history.push(ADD_CLIENT_ROUTE);
    };

    const refreshButtonClickHandler = () => {
        fetchClients();
    };

    const cancelClickHandler = () => {
        history.replace(LIST_CLIENT_ROUTE);
    };

    const editButtonClickHandler = (clientId) => {
        history.push(EDIT_CLIENT_ROUTE.replace(':clientId', clientId));
    };

    const onItemDeletedHandler = (data) => {
        setRefresh(refresh + 1)
    };
    

    useEffect(() => {
        fetchClients();
        fetchOffices();
    }, [fetchClients, fetchOffices, refresh]);

    const [state, setState] = useState({
        globalFilter: ""
    })


    const handleChange = (evt) => {
        const value = evt.value;
        setState({
            ...state,
            [evt.target.props.name]: value
        });
        setPage(Object.assign({}, initialPageState, { page: 1 }));
    }


    const customFilterData = (e) => {
        console.log('customFilterData', state.globalFilter, e)
        const out = e?.filter(o => {
            console.log('o.clientName',o.clientName)
            return (state.globalFilter == '' || (state.globalFilter != '' && (o.clientName.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1)))

        })

        console.log('out',out)
        return out
    }

    const filteredClients = customFilterData(clients)
    console.log('filteredClients',filteredClients)
    const visibleClients = paginate(filteredClients, page.skip, page.take);
    console.log('visibleClients',visibleClients)
    const location = useLocation();
    const drawerExpanded =
        location.pathname.includes('new') || location.pathname.includes('edit');



    const filterField = <div>

        <Form render={({ allowSubmit, onSubmit }) => (
            <form className="k-form table-filter-form " style={{ display: 'flex', gap: '15px', width: '450px' }} onSubmit={(e) => e.preventDefault()}>

                <Field name="globalFilter" label="Search" component={Input} onChange={handleChange} />

            
            </form>
        )}
        />

    </div>





    /*const gridData = (e) => {
        //return customFilterData(orderBy(dataResult, sort)).slice(page.skip, page.take + page.skip)
        return customFilterData(orderBy(dataResult, sort)).slice(page.skip, page.take + page.skip)
    }*/

    return (
        <section>
            {loading && <LoadingPanel />}

            <StackLayout orientation="vertical" gap="1em">
                <PageTitle text="Atrain Clients" />

                <ClientGrid
                    clients={visibleClients}
                    totalClients={filteredClients.length}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChangeHandler}
                    onAddButtonClick={addButtonClickHandler}
                    onRefreshButtonClick={refreshButtonClickHandler}
                    onEditButtonClick={editButtonClickHandler}
                    onItemDeleted={onItemDeletedHandler}
                    filterField={filterField}
                />
            </StackLayout>
            <RightDrawer expanded={drawerExpanded}>
                <Switch>
                    <Route path={ADD_CLIENT_ROUTE}>
                        <NewClientForm
                            offices={offices}
                            onClientAdded={clientAddedHandler}
                            onAddClientCancel={cancelClickHandler}
                        />
                    </Route>
                    <Route path={EDIT_CLIENT_ROUTE}>
                        <EditClientForm
                            offices={offices}
                            onClientUpdated={clientUpdatedHandler}
                            onClientUpdateCancel={cancelClickHandler}
                        />
                    </Route>
                </Switch>
            </RightDrawer>
        </section>
    );
};

export default ClientPage;
