import React, { useState, useEffect } from "react";
import CustomDrawer from "components/layout/CustomDrawer";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { ROUTES } from 'routes';
import { getCompetencyModel, updateCompetencyModelName,publishCompetencyModel, closeCompetencyModel } from "api/competency-models";

import CRUDPage from 'components/shared/CRUDPage';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import CompetencyDetail from './competency/CompetencyDetail';
import CompetencyCreate from './competency/CompetencyCreate';
import { Route, Switch } from 'react-router-dom';
import EditCommandCell from 'components/shared/EditCommandCell';
import ReorderComboBox from "components/shared/ReorderComboBox";
import { reorderCompetency } from "api/competency";

import useNotifications from 'hooks/use-notifications';
import {
    createSuccessNotification,
    createErrorNotification,
  } from 'utils/notifications-creator';

import { pickLanguage } from "utils/utils";
import CustomField from 'components/shared/forms/CustomField';
import CustomInput from 'components/shared/forms/CustomInput';
import {
    validateMaxLength,
    validateRequiredArray,
    validateRequiredObject,
    validateRequiredString,
  } from 'utils/validations';
  

const validateRequired = (value) => {
    return (
      validateRequiredString(value) || validateMaxLength(value, 200)
    );
  };

const initialSort = [
    {
        field: "sort",
        dir: "asc",
    },
];

//Status -> 0 Draft - 1 Active - 2 Closed
const CompetencyModelDetail = (props) => {
    const history = useHistory();
    const [detail, setDetail] = React.useState({behaviors:[]});
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(true);
    const [drawerIsLoading, setDrawerIsLoading] = React.useState(true);
    const notifications = useNotifications();

    const [refresh, setRefresh] = React.useState(0);

    const onCloseDrawer = () => {
        setDrawerIsOpen(false)
        history.push(ROUTES.COMPETENCY_MODEL.BASE);
    }

    useEffect(() => {
        setDrawerIsOpen(true)
        setDrawerIsLoading(true)
        
        getCompetencyModel(props.match.params.competencyModelId).then(data => {
            //data.createdDateFormatted = new Date(data.createdDate).toLocaleString()//data.createdDate.substring(0,16)
            //data.modifiedDateFormatted = data.modifiedDate ? new Date(data.modifiedDate).toLocaleString() : '-'
console.log(data)
            setDetail(data);
            setDrawerIsLoading(false)

        })
    }, [props.match.params.clientId, refresh])



    const handleSubmit = async (dataItem) => {

        const p = props
        setDrawerIsLoading(true)
        try {
            await updateCompetencyModelName(dataItem.id, dataItem.name)
    
            notifications.push(
              createSuccessNotification('Item updated successfully.')
            );
            setRefresh(refresh + 1)
            p.onChange()

            //history.replace(LIST_OFFICE_ROUTE);
          } catch (err) {
            notifications.push(
              createErrorNotification('Something was wrong updating the item.')
            );
          } finally {
            setDrawerIsLoading(false)
          }

    };

    const onAddCompetency = (e,position) =>{
        console.log('onAddCompetency')
        history.push(ROUTES.COMPETENCY.ADD.replace(':competencyModelId', props.match.params.competencyModelId) + '?pos=' + position);
    }

    const onEditCompetency = (e) =>{
        console.log('onEditCompetency',e)
        history.push(ROUTES.COMPETENCY.EDIT.replace(':competencyModelId', props.match.params.competencyModelId).replace(':competencyId', e.dataItem.id));
    }

    
    

    const onPublishCompetency = async () => {
        
        const confirm = window.confirm(
            'Are you sure you want to publish the competency model?'
          );
          if (confirm) {
            const p = props

            try {
            await publishCompetencyModel(props.match.params.competencyModelId)
    
            notifications.push(
                createSuccessNotification('Competency model published successfully.')
            );
            setRefresh(refresh + 1)
            p.onChange()
    
            } catch (err) {
            notifications.push(
                createErrorNotification('Something was wrong publishing the competency model.')
            );
            } finally {
            setDrawerIsLoading(false)
            }
          }

    };

    const onCloseCompetency = async () => {

        const confirm = window.confirm(
            'Are you sure you want to close the competency model?'
          );
          if (confirm) {
            const p = props

            try {
            await closeCompetencyModel(props.match.params.competencyModelId)

            notifications.push(
                createSuccessNotification('Competency model closed successfully.')
            );
            setRefresh(refresh + 1)
            p.onChange()

            } catch (err) {
            notifications.push(
                createErrorNotification('Something was wrong publishing the competency model.')
            );
            } finally {
            setDrawerIsLoading(false)
            }

        }
    };

    const onOrderChange = (order,id) =>{
        console.log('onOrderChange>', order,id)
        reorderCompetency(id, order).then(data => {
            setRefresh(refresh + 1)
        })
        
    }

    return (<>
        <CustomDrawer visible={drawerIsOpen} loading={drawerIsLoading} onClose={onCloseDrawer} title={'COMPETENCY MODEL DETAIL'} size="">

            {!drawerIsLoading && <div><Form
                onSubmit={handleSubmit}
                initialValues={detail}
                render={(formRenderProps) => (
                    <FormElement>
                        <div className="innerContent" >
                        <div>
                            <div className="mb-3">
                                <CustomField name={"name"} label="Name *" component={CustomInput} maxLength={200} validator={validateRequired} />
                                {/*<Field name={"name"} component={Input} label={"Name"} />*/}
                            </div>

                        </div>

                        <div className="k-form-buttons">

                            
                              
                                
                            <div style={{display:'flex', width:'100%'}}>
                                <button
                                    type={"submit"}
                                    className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-success k-rounded-md"
                                    disabled={!formRenderProps.allowSubmit}
                                >
                                    Save competency model
                                </button>
                                <div style={{flexGrow:1}}></div>

                                {detail.status == 'D' && <Button
                                    type="button"
                                    themeColor="info"
                                    onClick={onPublishCompetency}
                                >
                                    Publish competency model
                                </Button>}


                                {detail.status == 'A' && <Button
                                    type="button"
                                    themeColor="warning"
                                    onClick={onCloseCompetency}
                                >
                                    Close competency model
                                </Button>}
                                &nbsp;
                                {/*<Button
                                    type="button"
                                    themeColor="error"
                                    onClick={onAddCompetency}
                                >
                                    Delete
                </Button>*/}
                </div>

                            </div>
<br/>
                                    
                        <div className="tableSection">
                    <div className="tableToolbar">
                        <h3 className="customDrawer__label">Competencies</h3>


                        <Button
                                    type="button"
                                    onClick={(e) => {onAddCompetency(e,detail.competencies.length + 1)}}
                                    disabled={detail.status != 'D'}
                                >
                                    Add competency
                                </Button>
                    </div>
                        
                    </div>
                        <div className="v-spacer"></div>
                        </div>
                 
                    </FormElement>

                )} />
                


                <CRUDPage
                                dataresult={detail.competencies}
                                tableFilters={null}
                                initialSort={initialSort}
                                onRowClick={onEditCompetency}
                                showAddButton={false}
                                showRefreshButton={false}
                            >
                                <GridColumn field="sort" title="Order" width={100} /> 

                                <GridColumn field="id" title="ID" width={100} />  
                                
                                <GridColumn
                                title={'Competency'}
                                cell={(props) => (
                                    <td>{pickLanguage(props.dataItem.localization)?.name}</td>
                                )}
                                />
                           
                                <GridColumn
                                title={'Positive'}
                                cell={(props) => (
                                    <td>{pickLanguage(props.dataItem.localization)?.positiveName}</td>
                                )}
                                />

                                <GridColumn
                                title={'Negative'}
                                cell={(props) => (
                                    <td>{pickLanguage(props.dataItem.localization)?.negativeName}</td>
                                )}
                                />

                                <GridColumn
                                title={'Description'}
                                cell={(props) => (
                                    <td>{pickLanguage(props.dataItem.localization)?.description}</td>
                                )}
                                />
                           
                                <GridColumn cell={(props) => <EditCommandCell text={'Edit'} onClick={()=>{onEditCompetency(props)}}  size="small" props={props} />} width="115" />


                                {detail.status == 'D' && <GridColumn
                                title={'Order'}
                                width="130" 
                                cell={(props) => (
                                    <td>{<ReorderComboBox size={detail.competencies.length} value={props.dataItem?.sort} onChange={(e) => onOrderChange(e,props.dataItem?.id)}/>}</td>
                                )}
                                />}

                                


                                {/*detail.competencies[0].localization.map((item,i) => <GridColumn
                                key={i}
                                title={item.cultureCode}
                                cell={(props) => (
                                    <td>{props.dataItem.localization[i]?.name}</td>
                                )}
                                />
                                )*/}


                            </CRUDPage>

                </div>
                }


                {<Switch>
                    <Route path={ROUTES.COMPETENCY.ADD} render={(props) => <CompetencyCreate {...props} onChange={()=>{setRefresh(refresh + 1)}} />}/>
                    <Route path={ROUTES.COMPETENCY.EDIT} render={(props) => <CompetencyDetail {...props} onChange={()=>{setRefresh(refresh + 1)}} />}/>
                </Switch>}
        </CustomDrawer>
    </>

    );
};

export default CompetencyModelDetail;