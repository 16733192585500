import { createAddOfficeAction } from 'action-creators/office-grid-action-creators';
import { createOffice } from 'api/offices';
import OfficeForm from 'components/office/OfficeForm';
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { LIST_OFFICE_ROUTE } from 'routes';
import { createErrorNotification, createSuccessNotification } from 'utils/notifications-creator';

const emptyOffice = {
  officeName: '',
};

const NewOfficeForm = (props) => {
  const [loading, setLoading] = useState(false);
  const notifications = useNotifications();
  const history = useHistory();

  const submitClickHandler = async (submittedOffice) => {
    const officeData = {
      officeName: submittedOffice.officeName.trim(),
    };

    setLoading(true);

    try {
      const office = await createOffice(officeData);
      // dispatchOfficesAction(createAddOfficeAction(office));
      notifications.push(
        createSuccessNotification('Office created successfully.')
      );
      props.onOfficeAdded?.(office);
      history.replace(LIST_OFFICE_ROUTE);
      // TODO: navigate to the last page?
    } catch (err) {
      notifications.push(
        createErrorNotification('Something was wrong saving the office.')
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingPanel />}
      <OfficeForm
        data={emptyOffice}
        onSubmitClick={submitClickHandler}
        onCancelClick={props.onAddOfficeCancel}
      />
    </>
  );
};

export default NewOfficeForm;
