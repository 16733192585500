import { getCompetencyModel, updateCompetencyModelName,publishCompetencyModel, closeCompetencyModel, deleteCompetencyModel } from "api/competency-models";

import {
    createSuccessNotification,
    createErrorNotification,
  } from 'utils/notifications-creator';

 

export const actionPublishCompetency = async (competencyModelId, notifications) => {
 
        const confirm = window.confirm(
            'Are you sure you want to publish the competency model?'
          );
          if (confirm) {
            

            try {
            await publishCompetencyModel(competencyModelId)
    
            notifications.push(
                createSuccessNotification('Competency model published successfully.')
            );

            return true
            //setRefresh(refresh + 1)
            //p.onChange()
    
            } catch (err) {
            notifications.push(
                createErrorNotification('Something was wrong publishing the competency model.')
            );
            return false
            } finally {
            //setDrawerIsLoading(false)
            }
          }

    };

    export const actionCloseCompetency = async (competencyModelId, notifications) => {

        const confirm = window.confirm(
            'Are you sure you want to close the competency model?'
          );
          if (confirm) {
            //const p = props

            try {
            await closeCompetencyModel(competencyModelId)

            notifications.push(
                createSuccessNotification('Competency model closed successfully.')
            );
            //setRefresh(refresh + 1)
            //p.onChange()

            } catch (err) {
            notifications.push(
                createErrorNotification('Something was wrong publishing the competency model.')
            );
            } finally {
            //setDrawerIsLoading(false)
            }

        }
    };


    
    export const actionDeleteCompetency = async (competencyModelId, notifications) => {

        const confirm = window.confirm(
            'Are you sure you want to delete the competency model?'
          );
          if (confirm) {
            //const p = props

            try {
            await deleteCompetencyModel(competencyModelId)

            notifications.push(
                createSuccessNotification('Competency model deleted successfully.')
            );
            //setRefresh(refresh + 1)
            //p.onChange()

            } catch (err) {
            notifications.push(
                createErrorNotification('Something was wrong deleting the competency model.')
            );
            } finally {
            //setDrawerIsLoading(false)
            }

        }
    };