import { REPLACE_RESOURCES, UPDATE_RESOURCE, ADD_RESOURCE } from 'actions/resource-grid-actions';

export const createReplaceResourceAction = (resources) => {
  return { type: REPLACE_RESOURCES, resources };
};

export const createUpdateResourceAction = (resourceId, resourceUpdateData) => {
  return { type: UPDATE_RESOURCE, resourceId, resourceUpdateData };
};

export const createAddResourceAction = (resource) => {
  return { type: ADD_RESOURCE, resource };
};