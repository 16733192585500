import { getPerson, updatePerson } from 'api/persons-api';
import { getClients } from "api/clients";
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { LIST_PERSON_ROUTE } from 'routes';
import {
  createSuccessNotification,
  createErrorNotification,
} from 'utils/notifications-creator';
import PersonForm from './PersonForm';
import { deletePerson } from 'api/persons-api';

const EditPersonForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState();

  const params = useParams();
  const personId = parseInt(params.personId);

  const notifications = useNotifications();
  const history = useHistory();

  const submitClickHandler = async (submittedFormData) => {
    const personData = {
      firstName: submittedFormData.firstName.trim(),
      lastName: submittedFormData.lastName.trim(),
      email: submittedFormData.email.trim(),
      clientId: submittedFormData.clientId,
    };
    try {
      await updatePerson(personId, personData);
      notifications.push(
        createSuccessNotification('Person updated successfully.')
      );
      props.onPersonUpdated?.(personId, submittedFormData);
      history.replace(LIST_PERSON_ROUTE);
    } catch (err) {
      notifications.push(
        createErrorNotification('Something was wrong updating the person.')
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchPersonFormData = useCallback(
    async (personId) => {
      setLoading(true);
      try {
        const person = await getPerson(personId);
        const clients = await getClients();
        
        setFormData({
          personId:person.personId,
          firstName: person.firstName.trim(),
          lastName: person.lastName.trim(),
          email: person.email.trim(),
          clientId:person.clientId,
          clients:clients,
          isFocusPerson:person.isFocusPerson,
          isRater:person.isRater,
          editAvailable:person.editAvailable,
          clientChangeAvailable:person.clientChangeAvailable,
          deleteAvailable:person.deleteAvailable
        });
      } catch (err) {
        notifications.push({ type: 'error', text: err.message });
        history.push(LIST_PERSON_ROUTE);
      } finally {
        setLoading(false);
      }
    },
    [history]
  );

  useEffect(() => {
    fetchPersonFormData(personId);
  }, [fetchPersonFormData, personId]);

  
  const onDeleteItem = async () => {
    const confirm = window.confirm(
        'Are you sure you want to delete this item?'
      );
      if (confirm) {
        const p = props

        console.log(personId)
        try {
          await deletePerson(personId)
          props.onPersonDeleted()

          notifications.push(
              createSuccessNotification('AItem deleted successfully.')
          );
        
          //p.onChange()

        } catch (err) {
        notifications.push(
            createErrorNotification('Something was wrong deleting the item.')
        );
        } finally {
        
        }
      }

};

  return loading ? (
    <LoadingPanel />
  ) : (
    <PersonForm
      data={formData}
      onSubmitClick={submitClickHandler}
      onCancelClick={props.onPersonUpdateCancel}
      onDeleteItem={onDeleteItem}
    />
  );
};

export default EditPersonForm;
