import {
  DropDownButton,
  DropDownButtonItem,
} from '@progress/kendo-react-buttons';

const ActionGridColumn = (props) => {
  // console.log(props.dataItem);
  const isDraft = props.dataItem.status === 0;
  const isOpen = props.dataItem.status === 1;
  const isEditAvailable = true//isDraft; // edit available only in draft status
  const isDeleteAvailable = isDraft; // delete available only in draft status
  const isPublishAvailable = isDraft;
  const isCloseAvailable = isOpen;
  // const isViewAvailable = false;

  const itemClickHandler = (e) => e.item.onClick?.();

  return (
    <td style={{ textAlign: 'right' }}>
      <DropDownButton
        icon="more-vertical"
        fillMode="flat"
        onItemClick={itemClickHandler}
        popupSettings={{ anchorAlign: { horizontal: 'left', vertical: 'bottom' }, popupAlign: { horizontal: 'right', vertical: 'bottom' } }}
      >
        <DropDownButtonItem
          text="Edit"
          icon="edit"
          disabled={!isEditAvailable}
          onClick={props.onEditClick.bind(null, props.dataItem.projectId)}
        />
        <DropDownButtonItem
          text="Delete"
          icon="delete"
          disabled={!isDeleteAvailable}
          onClick={props.onDeleteClick.bind(null, props.dataItem)}
        />
        {isPublishAvailable && (
          <DropDownButtonItem
            text="Publish"
            icon="share"
            onClick={props.onPublishClick?.bind(null, props.dataItem)}
          />
        )}
        {isCloseAvailable && (
          <DropDownButtonItem
            text="Close"
            icon="lock"
            onClick={props.onCloseClick?.bind(null, props.dataItem)}
          />
        )}
      </DropDownButton>
    </td>
  );
};

export default ActionGridColumn;
