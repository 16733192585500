import { ADD_CLIENT, UPDATE_CLIENT, REPLACE_CLIENTS } from 'actions/client-grid-actions';

const clientGridReducer = (clients = [], action) => {
  switch (action.type) {
    case REPLACE_CLIENTS:
      return action.clients;
    case ADD_CLIENT:
      return [...clients, action.client];
    case UPDATE_CLIENT:
      const clientsCopy = [...clients];
      const modifiedClientIndex = clientsCopy.findIndex(
        (client) => client.clientId === action.clientId
      );
      console.log('modifiedClientIndex: ' + modifiedClientIndex);
      clientsCopy[modifiedClientIndex] = {
        ...clientsCopy[modifiedClientIndex],
        ...action.clientUpdateData,
      };
      return clientsCopy;
    default:
      return clients;
  }
};

export default clientGridReducer;
