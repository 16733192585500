import {
  DropDownButton,
  DropDownButtonItem,
} from '@progress/kendo-react-buttons';
import useNotifications from 'hooks/use-notifications';
import { createErrorNotification, createSuccessNotification } from 'utils/notifications-creator';

const PersonActionGridColumn = (props) => {
  const notifications = useNotifications();
  const isDeleteAvailable = props.isDeleteAvailable; // delete available only in draft status
  const isAnonymizationAvailable = props.isAnonymizationAvailable;
  const isAnonymizationCancellationAvailable = props.isAnonymizationCancellationAvailable;

  const itemClickHandler = (e) => e.item.onClick?.();


  const onDeleteHandler = async (dataItem) => {
        
    const confirm = window.confirm(
        'Are you sure you want to delete this item?'
      );
      if (confirm) {
        const p = props

        try {
          await props.deleteFunction(dataItem[props.idKey])
          props.onDeleted(dataItem)
    
        notifications.push(
            createSuccessNotification('Item delete successfully.')
        );

        } catch (err) {
        notifications.push(
            createErrorNotification('Something was wrong deleting the item.')
        );
        } finally {
        
        }
      }

};

const onAnonymizationHandler = async (dataItem) => {
        
  const confirm = window.confirm(
      'Are you sure you want to anonymize data for this user?'
    );
    if (confirm) {

      try {
        
        await props.anonymizationFunction(dataItem[props.idKey])
        props.onAnonymization(dataItem)
  
      notifications.push(
          createSuccessNotification('Operatino successfully scheduled')
      );

      } catch (err) {
      notifications.push(
          createErrorNotification('Something was wrong sending the request.')
      );
      } finally {
      
      }
    }

};

const onAnonymizationCancellationHandler = async (dataItem) => {
        
  const confirm = window.confirm(
      'Are you sure you want to cancel the anonymization request for this user?'
    );
    if (confirm) {

      try {
        await props.anonymizationCancellationFunction(dataItem[props.idKey])
        props.onAnonymizationCancellation(dataItem)
  
      notifications.push(
          createSuccessNotification('Operation successfully canceled')
      );

      } catch (err) {
      notifications.push(
          createErrorNotification('Something was wrong sending the request.')
      );
      } finally {
      
      }
    }

};


  return (
    <td style={{ textAlign: 'right' }}>
      {(isDeleteAvailable || isAnonymizationAvailable || isAnonymizationCancellationAvailable) && <DropDownButton
        icon="more-vertical"
        fillMode="flat"
        onItemClick={itemClickHandler}
        popupSettings={{ anchorAlign: { horizontal: 'left', vertical: 'bottom' }, popupAlign: { horizontal: 'right', vertical: 'bottom' } }}
      >
      
        {isAnonymizationAvailable && <DropDownButtonItem
            text="Anonymize user data"
            icon='close'
            disabled={!isAnonymizationAvailable}
            onClick={onAnonymizationHandler.bind(null, props.dataItem)}
          />}

        {isAnonymizationCancellationAvailable && <DropDownButtonItem
            text="Cancel user data anonymization"
            icon="reset"
            disabled={!isAnonymizationCancellationAvailable}
            onClick={onAnonymizationCancellationHandler.bind(null, props.dataItem)}
        />}


<DropDownButtonItem
          text="Delete"
          icon="delete"
          disabled={!isDeleteAvailable}
          onClick={onDeleteHandler.bind(null, props.dataItem)}
        />

       
      </DropDownButton>}
    </td>
  );
};

export default PersonActionGridColumn;
