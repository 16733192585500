import { createAddResourceAction } from 'action-creators/resource-grid-action-creators';
import { createResource } from 'api/resources';
import { getClients } from "api/clients";
import ResourceForm from 'components/resource/ResourceForm';
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LIST_RESOURCE_ROUTE } from 'routes';
import { createErrorNotification, createSuccessNotification } from 'utils/notifications-creator';



const NewResourceForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    resourceId:null,
    firstName: '',
    lastName: '',
    email: '',
    clientId:null,
    clients:[]
  });

  const notifications = useNotifications();
  const history = useHistory();

  const submitClickHandler = async (submittedResource) => {
    
    const resourceData = {
      firstName: submittedResource.firstName.trim(),
      lastName: submittedResource.lastName.trim(),
      email: submittedResource.email.trim(),
      clientId: submittedResource.clientId
    };

    setLoading(true);

    try {
      const resource = await createResource(resourceData);
      // dispatchResourcesAction(createAddResourceAction(resource));
      notifications.push(
        createSuccessNotification('Resource created successfully.')
      );
      props.onResourceAdded?.(resource);
      history.replace(LIST_RESOURCE_ROUTE);
      // TODO: navigate to the last page?
    } catch (err) {
      notifications.push(
        createErrorNotification('Something was wrong saving the resource.')
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchResourceFormData = useCallback(
    async () => {
      setLoading(true);
      try {
        const clients = await getClients();

        setFormData({
          clients:clients
        });
      } catch (err) {
        notifications.push({ type: 'error', text: err.message });
        history.push(LIST_RESOURCE_ROUTE);
      } finally {
        setLoading(false);
      }
    },
    [history, notifications]
  );

  useEffect(() => {
    fetchResourceFormData();
  }, [fetchResourceFormData]);


  return (
    <>
    
      {loading && <LoadingPanel />}
      <ResourceForm
        data={formData}
        onSubmitClick={submitClickHandler}
        onCancelClick={props.onAddResourceCancel}
      />
    </>
  );
};

export default NewResourceForm;
