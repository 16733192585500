import { ADD_PERSON, UPDATE_PERSON, REPLACE_PERSONS } from 'actions/person-grid-actions';

const personGridReducer = (persons = [], action) => {
  switch (action.type) {
    case REPLACE_PERSONS:
      return action.persons;
    case ADD_PERSON:
      return [...persons, action.person];
    case UPDATE_PERSON:
      const personsCopy = [...persons];
      const modifiedPersonIndex = personsCopy.findIndex(
        (person) => person.personId === action.personId
      );
      console.log('modifiedPersonIndex: ' + modifiedPersonIndex);
      personsCopy[modifiedPersonIndex] = {
        ...personsCopy[modifiedPersonIndex],
        ...action.personUpdateData,
      };
      return personsCopy;
    default:
      return persons;
  }
};

export default personGridReducer;
