import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useState } from 'react';


const ReorderComboBox = (props) => {
  const { size } = props;
  //const data = Array.from(Array(size).keys())
  const data = Array.from({length: size}, (_, i) => i + 1)

  const [value, setValue] = useState(props.value);

  const handleChange = (event) => {
    setValue(event.value);
    console.log('handleChange>',event.value)
    props.onChange(event.value)
    
  };


  return (
    <DropDownList
      data={data}
      value={value}
      onChange={handleChange}/>
  );
};

export default ReorderComboBox;