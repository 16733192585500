import { Error } from '@progress/kendo-react-labels';

const CustomFieldWrapper = (props) => {
  const { validationMessage, visited } = props;
  return (
    <div className="custom-field-wrapper">
      {props.children}
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export default CustomFieldWrapper;
