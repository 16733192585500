import { getOffice, updateOffice } from 'api/offices';
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { LIST_OFFICE_ROUTE } from 'routes';
import {
  createSuccessNotification,
  createErrorNotification,
} from 'utils/notifications-creator';
import OfficeForm from './OfficeForm';

const EditOfficeForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState();

  const params = useParams();
  const officeId = parseInt(params.officeId);

  const notifications = useNotifications();
  const history = useHistory();

  const submitClickHandler = async (submittedFormData) => {
    const officeData = {
      officeName: submittedFormData.officeName.trim(),
    };
    try {
      const office = await updateOffice(officeId, officeData);
      notifications.push(
        createSuccessNotification('Office updated successfully.')
      );
      
      props.onOfficeUpdated?.(officeId, {...office, submittedFormData});
      history.replace(LIST_OFFICE_ROUTE);
    } catch (err) {
      notifications.push(
        createErrorNotification('Something was wrong updating the office.')
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchOfficeFormData = useCallback(
    async (officeId) => {
      setLoading(true);
      try {
        const office = await getOffice(officeId);
        setFormData({
          officeName: office.officeName,
        });
      } catch (err) {
        notifications.push({ type: 'error', text: err.message });
        history.push(LIST_OFFICE_ROUTE);
      } finally {
        setLoading(false);
      }
    },
    [history, notifications]
  );

  useEffect(() => {
    fetchOfficeFormData(officeId);
  }, [fetchOfficeFormData, officeId]);

  return loading ? (
    <LoadingPanel />
  ) : (
    <OfficeForm
      data={formData}
      onSubmitClick={submitClickHandler}
      onCancelClick={props.onOfficeUpdateCancel}
    />
  );
};

export default EditOfficeForm;
