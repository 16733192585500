import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
  Card,
  CardBody,
  CardHeader,
  StackLayout,
} from '@progress/kendo-react-layout';
import { PAGE_SIZES } from 'settings/grid-settings';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import DeleteActionGridColumn from 'components/shared/DeleteActionGridColumn';
import { deleteClient } from 'api/clients';

const ClientGrid = (props) => {
  const editClickHandler = (clientId) => {
    props.onEditButtonClick?.(clientId);
  };

  return (
    <Card className="clientGrid pageGrid">
      <CardHeader>
        <StackLayout orientation="horizontal">
          <div style={{textAlign:"left"}}>
            <Button
              type="button"
              themeColor="primary"
              onClick={props.onAddButtonClick}
              className="btnAdd"
            >
              Add Client
            </Button>
          </div>
          <div style={{textAlign:"right"}}>
              <div className='clientGridToolbar pageGridToolbar'>
              {props.filterField}


              <Button
                type="button"
                themeColor="dark"
                onClick={props.onRefreshButtonClick}
              >
                <span className="k-icon k-i-reload"></span> Refresh
              </Button>
              </div>
         
          </div>
        </StackLayout>
      </CardHeader>
      <CardBody>
        <Grid
          id={props.id}
          data={props.clients}
          total={props.totalClients}
          pageable={{ type: 'input', pageSizes: PAGE_SIZES }}
          skip={props.skip}
          take={props.take}
          onPageChange={props.onPageChange}
        >
          <GridColumn field="clientId" title="Client Id" />
          <GridColumn field="clientName" title="Client Name" />
          <GridColumn field="officeName" title="Office Name" />

          <GridColumn field="projectsCount" title="Proj. count" />
          <GridColumn field="personsCount" title="Pers. count" />

          <GridColumn field="createdDate" title="Created Date" format="{0:d}" />
          <GridColumn
            field="modifiedDate"
            title="Modified Date"
            format="{0:d}"
          />
          <GridColumn
            cell={(props) => (
              <td>
                <Button
                  type="button"
                  themeColor="tertiary"
                  size="small"
                  onClick={editClickHandler.bind(null, props.dataItem.clientId)}
                >
                  Edit
                </Button>
              </td>
            )}
          />

<GridColumn
        width="100"
        cell={(cellProps) => (
          <DeleteActionGridColumn
            {...cellProps}
            idKey="clientId"
            deleteFunction={deleteClient}
            onDeleted={props.onItemDeleted}
            isDeleteAvailable={cellProps.dataItem.deleteAvailable}
          />
        )}
      />


        </Grid>
      </CardBody>
    </Card>
  );
};

export default ClientGrid;
