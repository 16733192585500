import CountryFlag from 'components/shared/CountryFlag';

const LanguageGridColumn = (props) => {
  return (
    <td>
      <CountryFlag cultureCode={props.dataItem.cultureCode} />
    </td>
  );
};

export default LanguageGridColumn;