import { Input } from '@progress/kendo-react-inputs';
import CustomFieldWrapper from './CustomFieldWrapper';
import LengthCounter from './LengthCounter';

const CustomInput = (props) => {
  const { validationMessage, visited, maxLength, ...others } = props;
  return (
    <CustomFieldWrapper
      validationMessage={validationMessage}
      visited={visited}
      name={props.name}
    >
      <Input {...others} maxLength={maxLength} />
      {maxLength && (
        <LengthCounter
          current={props.value?.length}
          max={maxLength}
          className="length-counter"
        />
      )}
    </CustomFieldWrapper>
  );
};

export default CustomInput;
