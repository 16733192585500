import { authFetch } from "api";

const API_BASE_PATH = '/api';

export const getAssessmentByProjectId = async (projectId) => {
  const httpResponse = await authFetch(`${API_BASE_PATH}/projects/${projectId}/assessments`);
  if(httpResponse.ok) {
    return await httpResponse.json();
  }
  throw httpResponse;
}


export const resetAssessment = async (assessmentId, personId) => {
  const httpResponse = await authFetch(`${API_BASE_PATH}/assessments/${assessmentId}/reset/${personId}`, {
    method: 'DELETE'
  });

  if(!httpResponse.ok) {
    throw httpResponse;
  }

  return {}
}

export const reOpenAssessment = async (assessmentId) => {
    const httpResponse = await authFetch(`${API_BASE_PATH}/assessments/${assessmentId}/reopen`, {
        method: 'PATCH'
    });

    if (!httpResponse.ok) {
        throw httpResponse;
    }

    return {}
}