import { updateBkoUser } from 'api/bkoUsers-api';
import { getOffices } from "api/offices";
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { LIST_BKOUSER_ROUTE } from 'routes';
import {
  createSuccessNotification,
  createErrorNotification,
} from 'utils/notifications-creator';
import BkoUserForm from './BkoUserForm';

const EditBkoUserForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState();
console.log('bkouser',props.bkoUser)
  //const params = useParams();
  const bkoUser = props.bkoUser
  const adminId = parseInt(props.bkoUser?.adminId);

  const notifications = useNotifications();
  const history = useHistory();

  const submitClickHandler = async (submittedFormData) => {
    const bkoUserData = {
      email: submittedFormData.email.trim(),
      adminId: submittedFormData.adminId,
      officeId: submittedFormData.officeId,
    };
    try {
      await updateBkoUser(adminId, bkoUserData);
      notifications.push(
        createSuccessNotification('User updated successfully.')
      );
      props.onBkoUserUpdated?.(adminId, submittedFormData);
      history.replace(LIST_BKOUSER_ROUTE);
    } catch (err) {
      notifications.push(
        createErrorNotification('Something was wrong updating the user.')
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchBkoUserFormData = useCallback(
    async (adminId) => {
      setLoading(true);
      try {
        //const bkoUser = await getBkoUser(adminId);
        const offices = await getOffices();

        setFormData({
          adminId:bkoUser.adminId,
          //firstName: bkoUser.firstName.trim(),
          //lastName: bkoUser.lastName.trim(),
          email: bkoUser.email.trim(),
          officeId:bkoUser.officeId,
          offices:offices,
          //isFocusBkoUser:bkoUser.isFocusBkoUser
        });
      } catch (err) {
        notifications.push({ type: 'error', text: err.message });
        history.push(LIST_BKOUSER_ROUTE);
      } finally {
        setLoading(false);
      }
    },
    [history, notifications]
  );

  useEffect(() => {
    fetchBkoUserFormData(adminId);
  }, [fetchBkoUserFormData, adminId]);

  return loading ? (
    <LoadingPanel />
  ) : (
    <BkoUserForm
      data={formData}
      onSubmitClick={submitClickHandler}
      onCancelClick={props.onBkoUserUpdateCancel}
    />
  );
};

export default EditBkoUserForm;
