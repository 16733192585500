import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList  } from "@progress/kendo-react-dropdowns";
import { useState, useContext, useCallback } from 'react';
import UserInfoContext from 'stores/user-context';
import CustomField from 'components/shared/forms/CustomField';
import CustomComboBox from 'components/shared/forms/CustomComboBox';
import debounce from 'lodash.debounce';

import { getClients } from "api/clients";


import { AutoComplete } from "@progress/kendo-react-dropdowns";

import {
  validateRequiredObject,
} from 'utils/validations';

// TODO: move outside
const maxLengthValidator = (value, maxLength) =>
  value?.trim().length > maxLength ? `Max ${maxLength} characters` : '';
  
const requiredValidator = (value) => {return (value?.trim() === '' ? 'Required' : '')};

const clientNameValidator = (firstName) =>
  requiredValidator(firstName) || maxLengthValidator(firstName, 200);

const ErrorInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const AutocompleteInput = (fieldRenderProps) => {
  const { validationMessage, visited, onChange, autocompletedata, ...others } = fieldRenderProps;
 
  //const [open, setOpen] = useState(false);


  const handleChange = (event) => {
    onChange(event)
    /*if(event.target.value.length>=3){
      setOpen(true)
    }else{
      setOpen(false)
    }*/
  };

  const focusedItemIndex = (data, inputText, textField) => {
  
    return null;
};

  return (
    <div>
      
      <AutoComplete
      data={autocompletedata}
      textField="text"
      onChange={handleChange}
      
      focusedItemIndex={focusedItemIndex}
      {...others}
    />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};



const validateOffice = (data) => {
  return (
    validateRequiredObject(data)
  );
};

const ErrorComboBox = (fieldRenderProps) => {

  const { validationMessage, visited, comboData,value,onChange, defaultValue, dataItemKey, textField, ...others } = fieldRenderProps;
  const dv = comboData?.find( e => e.officeId == defaultValue)

const [state, setState] = useState({
  ...dv,
  });

  const handleChange = (event) => {
    onChange({value:event.target.value.officeId})
    setState(event.target.value);
  };





  return (
    <div>

       <DropDownList
                    style={{
                      width: "100%",
                    }}
                    data={comboData}
                    dataItemKey={dataItemKey}
                    textField={textField}
                    value={state}
                    onChange={handleChange}
                    
                    {...others}
                  />

     
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};




const ClientForm = (props) => {
  const userInfo = useContext(UserInfoContext);

  const [formdata, setFormdata] = useState(props.data);
  
  const [currentOffice, setCurrentOffice] = useState(formdata.officeId); 

  const [clients, setClients] = useState([]);

     
  const handleChangeAutocomplete = (event) => {
    setClients([]);
    fetchClients(event.target.value); 
  }

  const handleChangeOffice = (event) => {
    setCurrentOffice(event.value);
  
  }

  const fetchClients = useCallback(

    debounce(async (txt) => {


      
      const clients = await getClients({search:txt, officeId:currentOffice });

      const aClient = clients.map(c => {
        return {
          text:c.clientName,
          id:c.clientId
        }
      })

      setClients(aClient)

    }, 100),

 [currentOffice]);





  return (
    <Form
      initialValues={formdata}
      onSubmit={props.onSubmitClick}
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          <fieldset className="k-form-fieldset">

            <legend className="k-form-legend">
              {props.data.clientId ? 'Edit' : 'Create'} Client
            </legend>

            <div className="mb-3">

              

<Field
  comboData={props.data.offices}
  name="officeId"
  component={ErrorComboBox}
  label="Office"
  textField="officeName"
  dataItemKey="officeId"
  defaultValue={props.data.officeId}
  validator={(value) => {validateOffice(value,props.data)}}
  disabled={!userInfo.isSuperAdmin}
  onChange={handleChangeOffice}
/>

</div>

            <div className="mb-3">

              {props.data.clientId && <Field
                name="clientName"
                component={ErrorInput}
                label="Client Name"
                validator={clientNameValidator}
        />}
              

              {!props.data.clientId && <Field
                name="clientName"
                component={AutocompleteInput}
                label="Client Name"
                autocompletedata={clients}
                onChange={handleChangeAutocomplete}
                validator={clientNameValidator}
                
              />}


            </div>
            

            
         
          </fieldset>
          <div className="k-form-buttons">
            <Button
              type="button"
              themeColor="inverse"
              onClick={props.onCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!formRenderProps.allowSubmit}
              themeColor="primary"
            >
              Submit
            </Button>
          </div>
        </FormElement>
      )}
    />
  );
};

export default ClientForm;
