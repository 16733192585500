import { REPLACE_PERSONS, UPDATE_PERSON, ADD_PERSON } from 'actions/person-grid-actions';

export const createReplacePersonAction = (persons) => {
  return { type: REPLACE_PERSONS, persons };
};

export const createUpdatePersonAction = (personId, personUpdateData) => {
  return { type: UPDATE_PERSON, personId, personUpdateData };
};

export const createAddPersonAction = (person) => {
  return { type: ADD_PERSON, person };
};