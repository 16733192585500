import { Avatar } from '@progress/kendo-react-layout';
const StatusGridColumn = ({props}) => {
  console.log('props',props)
  return (
    <td style={{ textOverflow: 'unset' }}>

    <Avatar
      type="text"
      rounded="medium"
      className='assessmentStatusTag'
      themeColor={
        props.dataItem?.sessionStatus === 'N' ? 'success' : props.dataItem?.sessionStatus === 'C' ? 'error' : 'dark'
      }
      style={{
        width: 'auto',
        height: 'auto',
        padding: '0px 15px',
      }}
    >
      <small>
        {props.dataItem?.sessionStatusName}
      </small>
    </Avatar>
    </td>
  );
};

export default StatusGridColumn;