import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList  } from "@progress/kendo-react-dropdowns";
import { useState } from 'react';

import HtmlInput from 'components/shared/forms/HtmlInput';

// TODO: move outside
const maxLengthValidator = (value, maxLength) =>
  value?.trim().length > maxLength ? `Max ${maxLength} characters` : '';
  
const requiredValidator = (value) => {return (value?.trim() === '' ? 'Required' : '')};

const emailValidator = (value) => {let emailRegExp = new RegExp('^(.+)@(.+)\\.(.+)$'); return emailRegExp.test(value) ? '' : 'Value is not a valid email'};

const clientValidator = (value) => (value > 0 ? '' : 'Required');

const resourceNameValidator = (firstName) => requiredValidator(firstName)// || maxLengthValidator(firstName, 200);

const resourceEmailValidator = (email) =>
  requiredValidator(email) || maxLengthValidator(email, 200) || emailValidator(email);

const ErrorInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

/*const HtmlInput = (fieldRenderProps) => {
  const { validationMessage, visited, label, value, onChange, ...others} = fieldRenderProps;

  return (
    <div>
      <span className='k-floating-label-container'><label className='k-label'>{label}</label></span>
      <HtmlEditor value={value} onChange={onChange} {...others} key={others.id}/>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};*/



const ErrorComboBox = (fieldRenderProps) => {
  const { validationMessage, visited, comboData,value,onChange, defaultValue, dataItemKey, textField, ...others } = fieldRenderProps;
  const dv = comboData?.find( e => e.clientId == defaultValue)

const [state, setState] = useState({
  ...dv,
  });

  const handleChange = (event) => {
    onChange({value:event.target.value.clientId})
    setState(event.target.value);
  };

  return (
    <div>
    
       <DropDownList
                    style={{
                      width: "100%",
                    }}
                    data={comboData}
                    dataItemKey={dataItemKey}
                    textField={textField}
                    value={state}
                    onChange={handleChange}
                    
                    {...others}
                  />

     
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};


const ResourceForm = (props) => {
 
  return (
    <>
    <Form
      initialValues={props.data}
      onSubmit={props.onSubmitClick}
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          <fieldset className="k-form-fieldset">
            <legend className="k-form-legend">
              Edit Resource
            </legend>
            {<div className="mb-3">
              <Field
                name="resourceKey"
                component={ErrorInput}
                label="resourceKey"
                disabled={true}
                validator={resourceNameValidator}
              />
            </div>}

            {
              props.data.values.map((f,x) =>
                <div className="mb-3">
                  <Field
                    name={'values[' + x + '].resourceValue'}
                    component={f.isHtml ? HtmlInput : ErrorInput}
                    label={f.cultureCode}
                    validator={resourceNameValidator}
                  />
                </div>
              )
            }
            


            
            {/*<div className="mb-3">
              <Field
              comboData={props.data.clients}
                name="clientId"
                component={ErrorComboBox}
                label="Client"
                textField="clientName"
                dataItemKey="clientId"  
                defaultValue={props.data.clientId}
                validator={clientValidator}
              />
        </div>*/}
            
          </fieldset>
          <div className="k-form-buttons">
            <Button
              type="button"
              themeColor="inverse"
              onClick={props.onCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!formRenderProps.allowSubmit}
              themeColor="primary"
            >
              Submit
            </Button>
          </div>
        </FormElement>
      )}
    />
    </>
  );
};

export default ResourceForm;
