const PROJECTS_ENTRY_ROUTE = '/projects';
const COMPETENCY_MODEL_ROUTES = {
    BASE: '/competency-model',
    ADD: '/competency-model/new',
    EDIT: '/competency-model/edit/:competencyModelId'
}

const COMPETENCY_ROUTES = {
    BASE: COMPETENCY_MODEL_ROUTES.EDIT,
    ADD: COMPETENCY_MODEL_ROUTES.EDIT + '/competency/new',
    EDIT: COMPETENCY_MODEL_ROUTES.EDIT + '/competency/edit/:competencyId'
}

const BEHAVIOR_ROUTES = {
    BASE: COMPETENCY_ROUTES.EDIT,
    ADD: COMPETENCY_ROUTES.EDIT + '/behavior/new',
    EDIT: COMPETENCY_ROUTES.EDIT + '/behavior/edit/:behaviorId'
}

const ASSESSMENT_ROUTES = {
    BASE: '/assessment/:projectId',
    EDIT: '/assessment/edit/:assessmentlId'
}

const INVITEES_ROUTES = {
    BASE: '/invitees/:projectId'
}

export const ROUTES = {
    DASHBOARD: '/',
    LOGIN: '/login',
    COMPETENCY_MODEL: COMPETENCY_MODEL_ROUTES,
    COMPETENCY:COMPETENCY_ROUTES,
    BEHAVIOR:BEHAVIOR_ROUTES,
    ASSESSMENT:ASSESSMENT_ROUTES,
    INVITEES:INVITEES_ROUTES,
    CLIENT: {
        BASE: '/client',
        EDIT: '/client/:clientId',
        ADD: '/client/add'
    },
    OFFICE: {
        BASE: '/office',
        ADD: '/new',
        EDIT: '/edit/:officeId'
    },
    PERSON: {
        BASE: '/person'
    },
    BKOUSER: {
        BASE: '/bkouser'
    },
    PROJECTS: {
        LIST: PROJECTS_ENTRY_ROUTE,
        ADD: PROJECTS_ENTRY_ROUTE + '/new',
        EDIT: PROJECTS_ENTRY_ROUTE + '/edit/:projectId'
    },
    CLIENT_APP_RESOURCE: {
        BASE: '/app-resource'
    },
    MAILING: {
        BASE: '/mailing'
    },
    RESOURCE: {
        BASE: '/resource',
        EDIT: '/resource/:resourceId',
        ADD: '/resource/add'
    }
};

export const OFFICE_BASE_ROUTE = '/office';
export const LIST_OFFICE_ROUTE = OFFICE_BASE_ROUTE;
export const ADD_OFFICE_ROUTE = OFFICE_BASE_ROUTE + '/new';
export const EDIT_OFFICE_ROUTE = OFFICE_BASE_ROUTE + '/edit/:officeId';

export const PERSON_BASE_ROUTE = '/person';
export const LIST_PERSON_ROUTE = PERSON_BASE_ROUTE;
export const ADD_PERSON_ROUTE = PERSON_BASE_ROUTE + '/new';
export const EDIT_PERSON_ROUTE = PERSON_BASE_ROUTE + '/edit/:personId';

export const CLIENT_BASE_ROUTE = '/client';
export const LIST_CLIENT_ROUTE = CLIENT_BASE_ROUTE;
export const ADD_CLIENT_ROUTE = CLIENT_BASE_ROUTE + '/new';
export const EDIT_CLIENT_ROUTE = CLIENT_BASE_ROUTE + '/edit/:clientId';

export const RESOURCE_BASE_ROUTE = '/resource';
export const LIST_RESOURCE_ROUTE = RESOURCE_BASE_ROUTE;
export const ADD_RESOURCE_ROUTE = RESOURCE_BASE_ROUTE + '/new';
export const EDIT_RESOURCE_ROUTE = RESOURCE_BASE_ROUTE + '/edit/:resourceIds';

export const BKOUSER_BASE_ROUTE = '/bkouser';
export const LIST_BKOUSER_ROUTE = BKOUSER_BASE_ROUTE;
export const ADD_BKOUSER_ROUTE = BKOUSER_BASE_ROUTE + '/new';
export const EDIT_BKOUSER_ROUTE = BKOUSER_BASE_ROUTE + '/edit/:adminId';