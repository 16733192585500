import { ComboBox } from '@progress/kendo-react-dropdowns';
import CustomFieldWrapper from './CustomFieldWrapper';

const CustomComboBox = (props) => {
  const { validationMessage, visited, ...others } = props;
  return (
    <CustomFieldWrapper validationMessage={validationMessage} visited={visited}>
      <ComboBox {...others} />
    </CustomFieldWrapper>
  );
};

export default CustomComboBox;