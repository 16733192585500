import { createAddPersonAction } from 'action-creators/person-grid-action-creators';
import { createPerson } from 'api/persons-api';
import { getClients } from "api/clients";
import PersonForm from 'components/person/PersonForm';
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LIST_PERSON_ROUTE } from 'routes';
import { createErrorNotification, createSuccessNotification } from 'utils/notifications-creator';



const NewPersonForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    personId:null,
    firstName: '',
    lastName: '',
    email: '',
    clientId:null,
    clients:[],
    editAvailable:true,
    clientChangeAvailable:true
  });

  const notifications = useNotifications();
  const history = useHistory();

  const submitClickHandler = async (submittedPerson) => {
    
    const personData = {
      firstName: submittedPerson.firstName.trim(),
      lastName: submittedPerson.lastName.trim(),
      email: submittedPerson.email.trim(),
      clientId: submittedPerson.clientId
    };

    setLoading(true);

    try {
      const person = await createPerson(personData);
      // dispatchPersonsAction(createAddPersonAction(person));
      notifications.push(
        createSuccessNotification('Person created successfully.')
      );
      props.onPersonAdded?.(person);
      history.replace(LIST_PERSON_ROUTE);
      // TODO: navigate to the last page?
    } catch (err) {
      notifications.push(
        createErrorNotification('Something was wrong saving the person. ' + err)
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchPersonFormData = useCallback(
    async () => {
      setLoading(true);
      try {
        const clients = await getClients();

        setFormData({
          ...formData,
          clients:clients
        });
      } catch (err) {
        notifications.push({ type: 'error', text: err.message });
        history.push(LIST_PERSON_ROUTE);
      } finally {
        setLoading(false);
      }
    },
    [history, notifications]
  );

  useEffect(() => {
    fetchPersonFormData();
  }, [fetchPersonFormData]);


  return (
    <>
    
      {loading && <LoadingPanel />}
      <PersonForm
        data={formData}
        onSubmitClick={submitClickHandler}
        onCancelClick={props.onAddPersonCancel}
      />
    </>
  );
};

export default NewPersonForm;
