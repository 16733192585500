import {
  DropDownButton,
  DropDownButtonItem,
} from '@progress/kendo-react-buttons';

const ActionGridColumn = (props) => {
  const {showEdit, showDelete, showPublish, showClose} = props

  const itemClickHandler = (e) => e.item.onClick?.();

  return (
    <td style={{ textAlign: 'right' }}>
      <DropDownButton
        icon="more-vertical"
        fillMode="flat"
        onItemClick={itemClickHandler}
        popupSettings={{ anchorAlign: { horizontal: 'left', vertical: 'bottom' }, popupAlign: { horizontal: 'right', vertical: 'bottom' } }}
      >
        <DropDownButtonItem
          text="Edit"
          icon="edit"
          disabled={!showEdit}
          onClick={props.onEditClick.bind(null, props.dataItem)}
        />
        <DropDownButtonItem
          text="Delete"
          icon="delete"
          disabled={!showDelete}
          onClick={props.onDeleteClick.bind(null, props.dataItem)}
        />
        {showPublish && (
          <DropDownButtonItem
            text="Publish"
            icon="share"
            onClick={props.onPublishClick?.bind(null, props.dataItem)}
          />
        )}
        {showClose && (
          <DropDownButtonItem
            text="Close"
            icon="lock"
            onClick={props.onCloseClick?.bind(null, props.dataItem)}
          />
        )}
      </DropDownButton>
    </td>
  );
};

export default ActionGridColumn;
