import { authFetch } from "api";

const API_BASE_PATH = '/api/project';

export const getProjects = async () => {
  const response = await authFetch(API_BASE_PATH);
  const projects = (await response.json()).map((project) => ({
    ...project,
    createdDate: project.createdDate && new Date(project.createdDate),
    modifiedDate: project.modifiedDate && new Date(project.modifiedDate),
    startDate: project.startDate && new Date(project.startDate),
    closedDate: project.closedDate && new Date(project.closedDate),
  }));
  return projects;
};

export const createProject = async (newProjectData) => {
  const response = await authFetch(API_BASE_PATH, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(newProjectData),
  });

  if (response.status === 201) {
    return await response.json();
  }

  throw response;
};

export const getProjectById = async (projectId) => {
  const response = await authFetch(`${API_BASE_PATH}/${projectId}`);
  // const response = await authFetch('/mock/project.json');
  if(response.ok) {
    return await response.json();
  }
  throw response;
}

export const updateProject = async (projectId, updateProjectData) => {
  const response = await authFetch(API_BASE_PATH + '/' + projectId, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(updateProjectData)
  });

  if(!response.ok) {
    throw response;
  }
}

export const deleteProject = async (projectId) => {
  const response = await authFetch(API_BASE_PATH + '/' + projectId, {
    method: 'DELETE'
  });

  if(!response.ok) {
    throw response;
  }
};

export const publishProject = async (projectId) => {
  const response = await authFetch(`${API_BASE_PATH}/${projectId}/publish`, {
    method: 'PATCH'
  });

  if(!response.ok) {
    throw response;
  }
};

export const closeProject = async (projectId) => {
  const response = await authFetch(`${API_BASE_PATH}/${projectId}/close`, {
    method: 'PATCH'
  });

  if(!response.ok) {
    throw response;
  }
};