const { Card, CardBody } = require('@progress/kendo-react-layout');

const PageTitle = (props) => {
  return (
    <Card>
      <CardBody>
        {props.customitems}
        <h1>{props.text}</h1>
      </CardBody>
    </Card>
  );
};

export default PageTitle;