import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
  Card,
  CardBody,
  CardHeader,
  StackLayout,
} from '@progress/kendo-react-layout';
import { PAGE_SIZES } from 'settings/grid-settings';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';

const BkoUserGrid = (props) => {
  const editClickHandler = (admin) => {
    props.onEditButtonClick?.(admin);
  };

  return (
    <Card className="pageGrid bkoUserGrid">
      <CardHeader>
        <StackLayout orientation="horizontal">
          {/*<div style={{textAlign:"left"}}>
            <Button
              type="button"
              themeColor="primary"
              onClick={props.onAddButtonClick}
              className="btnAdd"
            >
              Add BkoUser
            </Button>
  </div>*/}
          <div style={{textAlign:"right"}}>
              <div className='pageGridToolbar bkoUserGridToolbar'>
              {props.filterField}


              {<Button
                type="button"
                themeColor="dark"
                onClick={props.onRefreshButtonClick}
              >
                <span className="k-icon k-i-reload"></span> Refresh
              </Button>}
              </div>
         
          </div>
        </StackLayout>
      </CardHeader>
      <CardBody>
        <Grid
          id={props.id}
          data={props.bkoUsers}
          total={props.totalBkoUsers}
          pageable={{ type: 'input', pageSizes: PAGE_SIZES }}
          skip={props.skip}
          take={props.take}
          onPageChange={props.onPageChange}
        >


          <GridColumn field="adminId" title="Id" />
          <GridColumn field="email" title="Email" />
          <GridColumn field="officeName" title="Office name" />

          <GridColumn
            cell={(props) => (
              <td>
                <Button
                  type="button"
                  themeColor="tertiary"
                  size="small"
                  onClick={editClickHandler.bind(null, props.dataItem)}
                >
                  Edit
                </Button>
              </td>
            )}
          />
        </Grid>
      </CardBody>
    </Card>
  );
};

export default BkoUserGrid;
