import React, { useState, useEffect } from "react";
import CustomDrawer from "components/layout/CustomDrawer";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { ROUTES } from 'routes';
import { createBehaviorResource } from 'api/behaviorMarkers';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import useQuery from 'hooks/use-query';

import { LANGUAGES } from './../../../settings/languages';

import useNotifications from 'hooks/use-notifications';
import {
    createSuccessNotification,
    createErrorNotification,
} from 'utils/notifications-creator';

import CustomField from 'components/shared/forms/CustomField';
import CustomInput from 'components/shared/forms/CustomInput';
import {
    validateMaxLength,
    validateRequiredArray,
    validateRequiredObject,
    validateRequiredString,
  } from 'utils/validations';
  
  import HtmlInput from 'components/shared/forms/HtmlInput';
  
const validateRequired = (value) => {
    return (
      validateRequiredString(value) || validateMaxLength(value, 200)
    );
  };


const formFragment = LANGUAGES.map(l => {
    return {
        cultureCode: l.cultureCode,
        shortTitle: '',
        title: ''
    }
})

const BehaviorCreate = (props) => {
    const query = useQuery()
    const history = useHistory();
    const [detail, setDetail] = React.useState({
        competencyId: null,
        sort: 0,
        localization: formFragment
    });
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(true);
    const [drawerIsLoading, setDrawerIsLoading] = React.useState(false);
    const notifications = useNotifications();

    const onCloseDrawer = () => {
        setDrawerIsOpen(false)
        history.push(ROUTES.COMPETENCY.EDIT.replace(':competencyModelId', props.match.params.competencyModelId).replace(':competencyId', props.match.params.competencyId));
    }

    const handleSubmit = async (e) => {

        const isPositive = query.get('ispositive') == 'true' ? true : false;
        const position = query.get('pos') * 1 || 1
        const titleResources = e.localization.map(l => {
            return {
                "cultureCode": l.cultureCode,
                "resourceValue": l.title
            }
        })

        const shortTitleResources = e.localization.map(l => {
            return {
                "cultureCode": l.cultureCode,
                "resourceValue": l.shortTitle
            }
        })

        const data = {
            competencyId: props.match.params.competencyId * 1,
            isPositive,
            titleResources,
            shortTitleResources,
            sort: position
        }

        const p = props
        try {
            await createBehaviorResource(data)
            notifications.push(createSuccessNotification('Item updated successfully.'));
            p.onChange()
            history.push(ROUTES.BEHAVIOR.BASE.replace(':competencyModelId', props.match.params.competencyModelId).replace(':competencyId', props.match.params.competencyId));
        } catch (err) {
            notifications.push(createErrorNotification('Something was wrong updating the item.'));
        } finally {
            setDrawerIsLoading(false)
        }


    };

    const [selected, setSelected] = React.useState(0);

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    return (<>
        <CustomDrawer visible={drawerIsOpen} loading={drawerIsLoading} onClose={onCloseDrawer} title={'BEHAVIOR DETAIL'} size="">

            {!drawerIsLoading && <div>
                <Form
                    onSubmit={(e) => handleSubmit(e)}
                    initialValues={detail}
                    render={(formRenderProps) => (
                        <FormElement>

                            <TabStrip selected={selected} onSelect={handleSelect} className="fullWidthTab">

                                {detail.localization.map((item, i) => <TabStripTab title={item.cultureCode} key={i}>



                                    <div>
  

                                            <CustomField name={"localization[" + i + "].shortTitle"} label="Short title *" component={CustomInput} maxLength={200} validator={validateRequired} />
                                     
                                            <CustomField name={"localization[" + i + "].title"} label="Title *" component={HtmlInput}  validator={validateRequired} />
                           
                                    </div>

                                </TabStripTab>)}


                            </TabStrip>

                            <button
                                type={"submit"}
                                className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-success k-rounded-md"
                                disabled={!formRenderProps.allowSubmit}
                            >
                                CREATE
                            </button>

                        </FormElement>

                    )} />

            </div>}

        </CustomDrawer>
    </>

    );
};

export default BehaviorCreate;