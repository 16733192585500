import { REPLACE_BKOUSERS, UPDATE_BKOUSER, ADD_BKOUSER } from 'actions/bkoUser-grid-actions';

export const createReplaceBkoUserAction = (bkoUsers) => {
  return { type: REPLACE_BKOUSERS, bkoUsers };
};

export const createUpdateBkoUserAction = (adminId, bkoUserUpdateData) => {
  return { type: UPDATE_BKOUSER, adminId, bkoUserUpdateData };
};

export const createAddBkoUserAction = (bkoUser) => {
  return { type: ADD_BKOUSER, bkoUser };
};