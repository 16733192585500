import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { orderBy } from "@progress/kendo-data-query";

import { PAGE_SIZES, DEFAULT_PAGE_SIZE } from 'settings/grid-settings';

const defaultInitialSort = [
    {
        field: "id",
        dir: "desc",
    },
];

const initialDataState = {
    skip: 0,
    take: DEFAULT_PAGE_SIZE,
};

const PagedTable = (props) => {
    console.log('initialSort', props.initialSort)
    const [sort, setSort] = React.useState(props.initialSort || defaultInitialSort);
    const [page, setPage] = React.useState(initialDataState);

    const handleSortChange = (e) => {
        setSort(e.sort);
    }

    const handleRowClick = (evt) => {
        props.onRowClick(evt)
    }

    const pageChange = (event) => {
        console.log('pageChange', event)
        setPage(event.page);
    };

    const gridData = (e) => {
        return props.dataresult ? (orderBy(props.dataresult, sort).slice(page.skip, page.take + page.skip)) : []
    }

    useEffect(() => {
        setPage(Object.assign({},initialDataState,{page:1}));
    }, [props.dataresult]);

    
    return (
        <>
                {<Grid
                    onRowClick={handleRowClick}
                    data={gridData()}
                    sortable={true}
                    sort={sort}
                    onSortChange={handleSortChange}

                    skip={page.skip}
                    take={page.take}
                    total={props.dataresult?.length}
                    pageable={true}
                    onPageChange={pageChange}
                    pageable={{ type: 'input', pageSizes: PAGE_SIZES }}
                >

                    {props.children}
                   
                </Grid>}

        </>
    );
};

export default PagedTable;