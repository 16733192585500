import { Avatar } from '@progress/kendo-react-layout';

const ProjectStatusLabel = (props) => {
  return (
    <Avatar
      type="text"
      rounded="medium"
      themeColor={
        props.status === 0 ? 'dark' : props.status === 1 ? 'success' : 'error'
      }
      style={{
        width: 'auto',
        height: 'auto',
        padding: '0px 15px',
      }}
    >
      <small>
        {props.status === 0 ? 'Draft' : props.status === 1 ? 'Open' : 'Closed'}
      </small>
    </Avatar>
  );
};

export default ProjectStatusLabel;