import { msalInstance } from "index";

import { CLIENT_ID } from '../config';


export const pickLanguage = (localizations) =>{
    return localizations?.find(l => l.cultureCode == 'en-US')
}



export const getAccessToken = async () => {
    const instance = msalInstance;
    let accessToken = null;

    const account = instance.getAllAccounts()[0];
    console.log('------------------------');
    if(!!account) {
       console.log('!!account');

       try {
        const resp = await instance.acquireTokenSilent({ scopes: [ `api://${CLIENT_ID}/access_as_user` ], account: account })
        console.log('SET TOKEN',resp);
        accessToken = resp.accessToken
        
       } catch (err) {
        // console.log(err);
        if(err.name === 'InteractionRequiredAuthError') {
            try {
                const resp = await instance.acquireTokenPopup({ scopes: [ `api://${CLIENT_ID}/access_as_user` ] })
                accessToken = resp.accessToken
    
            } catch (err) {
                console.error(err)
            }
          }
          else {
            console.error(err);
          }
       }

    }
    else {
        instance.logoutRedirect({ postLogoutRedirectUri: '/' });
      console.log('No account here');
      accessToken = null
    }

    return  accessToken
    // console.log('------------------------');
  
}