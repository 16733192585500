import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
  Card,
  CardBody,
  CardHeader,
  StackLayout,
} from '@progress/kendo-react-layout';
import { PAGE_SIZES } from 'settings/grid-settings';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import PersonActionGridColumn from 'components/person/PersonActionGridColumn';
import { deletePerson, cancelRequestAnonymize, requestAnonymize } from 'api/persons-api';

import {
  DropDownButton,
  DropDownButtonItem,
} from '@progress/kendo-react-buttons';

const PersonGrid = (props) => {
  const editClickHandler = (personId) => {
    props.onEditButtonClick?.(personId);
  };

  const rowRender = (trElement, props) => {
    const isAnonymizationRequested = props.dataItem.isAnonymizationRequested;

    const trProps = {
      style: isAnonymizationRequested ? {opacity:0.5} : {opacity:1},
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps,
      },
      trElement.props.children
    );
  };

  return (
    <Card className="personGrid">
      <CardHeader>
        <StackLayout orientation="horizontal">
          <div style={{textAlign:"left"}}>
            <Button
              type="button"
              themeColor="primary"
              onClick={props.onAddButtonClick}
              className="btnAdd"
            >
              Add Person
            </Button>
          </div>
          <div style={{textAlign:"right"}}>
              <div className='personGridToolbar'>
              {props.filterField}


              {<Button
                type="button"
                themeColor="dark"
                onClick={props.onRefreshButtonClick}
              >
                <span className="k-icon k-i-reload"></span> Refresh
              </Button>}
              </div>
         
          </div>
        </StackLayout>
      </CardHeader>
      <CardBody>
        <Grid
          id={props.id}
          data={props.persons}
          total={props.totalPersons}
          pageable={{ type: 'input', pageSizes: PAGE_SIZES }}
          skip={props.skip}
          take={props.take}
          onPageChange={props.onPageChange}
          rowRender={rowRender}
        >
          <GridColumn field="personId" title="Person Id"  width="120"/>
          <GridColumn field="firstName" title="First Name" />
          <GridColumn field="lastName" title="Last Name" />
          <GridColumn field="email" title="Email" />
          <GridColumn field="clientName" title="Client" />
          <GridColumn field="projectsCount" title="Proj. count" width="120"/>
          
          <GridColumn field="createdDate" title="Created Date" format="{0:d}"  width="120"  />
          <GridColumn
            field="modifiedDate"
            title="Modified Date"
            format="{0:d}"
            width="120"
          />
          <GridColumn
            width="115"
            cell={(props) => (
              <td>
                <Button
                  type="button"
                  themeColor="tertiary"
                  size="small"
                  onClick={editClickHandler.bind(null, props.dataItem.personId)}
                >
                  Edit
                </Button>
              </td>
            )}
          />

          
          <GridColumn
          width="90"
          cell={(cellProps) => (
          <PersonActionGridColumn
            {...cellProps}
            idKey="personId"
            deleteFunction={deletePerson}
            onDeleted={props.onItemDeleted}

            anonymizationCancellationFunction={cancelRequestAnonymize}
            onAnonymizationCancellation={props.onRefreshRequested}

            anonymizationFunction={requestAnonymize}
            onAnonymization={props.onRefreshRequested}

            isDeleteAvailable={cellProps.dataItem.deleteAvailable}
            isAnonymizationAvailable={cellProps.dataItem.anonymizationAvailable}
            isAnonymizationCancellationAvailable={cellProps.dataItem.anonymizationCancellationAvailable}
          >

          </PersonActionGridColumn>
        )}
      />
        </Grid>
      </CardBody>
    </Card>
  );
};

export default PersonGrid;
