import * as React from 'react';
import '@progress/kendo-theme-material/dist/all.css';
import 'assets/style.css';
import 'style.css';
import TopMenu from 'components/layout/TopMenu';
import UserInfoContext from 'stores/user-context';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import DashboardPage from 'pages/DashboardPage';
import PersonPage from 'pages/PersonPage';
import OfficePage from 'pages/OfficePage';
import ClientPage from 'pages/ClientPage';
import ProjectPage from 'pages/ProjectPage';
import ResourcePage from 'pages/ResourcePage';
import AssessmentPage from 'pages/AssessmentPage';
import InviteesPage from 'pages/InviteesPage';
import BkoUserPage from 'pages/BkoUserPage';

import CompetencyModelPage from 'pages/CompetencyModelPage';
import { useState } from 'react';
import { ROUTES } from 'routes';
import NotificationStackProvider from 'components/shared/NotificationStackProvider';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { loginRequest } from 'msalConfig';
import { Button } from '@progress/kendo-react-buttons';
import OfficeSet from 'components/shared/OfficeSet';


const App = ({ msalInstance }) => {
  // const msalInstance = useMsal();
  // const msalInstance = props.msalInstance;
  const [userInfo, setUserInfo] = useState({});

  const azureSignInHandler = () => {
    msalInstance.loginRedirect(loginRequest);
  };

  const onUserInfoChange = (e) =>{
    setUserInfo(e)
  }

  const isSuperAdmin = userInfo.isSuperAdmin

  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <NotificationStackProvider>
          <AuthenticatedTemplate>

          <OfficeSet onUserInfoChange={onUserInfoChange}>

          
    <UserInfoContext.Provider value={userInfo}>
      
            <TopMenu isSuperAdmin={isSuperAdmin}/>

            <main>
              <Switch>
              {isSuperAdmin && <Route
                  path={ROUTES.COMPETENCY_MODEL.BASE}
                  component={CompetencyModelPage}
                />}
                <Route
                  path={ROUTES.DASHBOARD}
                  component={DashboardPage}
                  exact
                />
                <Route path={ROUTES.CLIENT.BASE} component={ClientPage} />
                <Route path={ROUTES.PERSON.BASE} component={PersonPage} />
                {isSuperAdmin && <Route path={ROUTES.OFFICE.BASE} component={OfficePage} />}
                <Route path={ROUTES.PROJECTS.LIST} component={ProjectPage} />
                <Route path={ROUTES.ASSESSMENT.BASE} component={AssessmentPage} />
                <Route path={ROUTES.INVITEES.BASE} component={InviteesPage} />

                {isSuperAdmin && <Route path={ROUTES.BKOUSER.BASE} component={BkoUserPage} />}
                {isSuperAdmin && <Route path={ROUTES.RESOURCE.LIST} component={ResourcePage} />}
                

              </Switch>
            </main>
            </UserInfoContext.Provider>
            </OfficeSet>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <div style={{ textAlign: 'center' }}>
              <h2>Welcome to Atrain Back Office</h2>
              <h4>Login to view content</h4>
              <Button themeColor="primary" onClick={azureSignInHandler}>
                Login
              </Button>
            </div>
          </UnauthenticatedTemplate>
        </NotificationStackProvider>
      </BrowserRouter>
    </MsalProvider>
  );
};

export default App;
