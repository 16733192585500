import { TextArea } from '@progress/kendo-react-inputs';
import { FloatingLabel } from '@progress/kendo-react-labels';
import CustomFieldWrapper from './CustomFieldWrapper';
import LengthCounter from './LengthCounter';

const CustomTextArea = (props) => {
  const { validationMessage, visited, label, id, maxLength, value, ...others } =
    props;
  return (
    <CustomFieldWrapper
      validationMessage={validationMessage}
      visited={visited}
      name={props.name}
    >
      <FloatingLabel
        className="k-textarea-container"
        label={label}
        editorId={id}
        editorValue={value}
      >
        <TextArea
          id={id}
          value={value}
          maxLength={maxLength}
          autoSize={true}
          {...others}
        />
      </FloatingLabel>
      {maxLength && (
        <LengthCounter
          current={props.value.length}
          max={maxLength}
          className="length-counter"
        />
      )}
    </CustomFieldWrapper>
  );
};

export default CustomTextArea;
