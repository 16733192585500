import { ADD_BKOUSER, UPDATE_BKOUSER, REPLACE_BKOUSERS } from 'actions/bkoUser-grid-actions';

const bkoUserGridReducer = (bkoUsers = [], action) => {
  switch (action.type) {
    case REPLACE_BKOUSERS:
      return action.bkoUsers;
    case ADD_BKOUSER:
      return [...bkoUsers, action.bkoUser];
    case UPDATE_BKOUSER:
      const bkoUsersCopy = [...bkoUsers];
      const modifiedBkoUserIndex = bkoUsersCopy.findIndex(
        (bkoUser) => bkoUser.adminId === action.adminId
      );
      console.log('modifiedBkoUserIndex: ' + modifiedBkoUserIndex);
      bkoUsersCopy[modifiedBkoUserIndex] = {
        ...bkoUsersCopy[modifiedBkoUserIndex],
        ...action.bkoUserUpdateData,
      };
      return bkoUsersCopy;
    default:
      return bkoUsers;
  }
};

export default bkoUserGridReducer;
