import { BEHAVIORMARKERS_API_BASE_ROUTE } from 'settings/api-settings';
import { authFetch } from "api";

export const createBehaviorResource = async (behavior) => {
  const url = BEHAVIORMARKERS_API_BASE_ROUTE
  const response = await authFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(behavior),
  });
  if (response.status === 201 || response.status === 200) {
    return await response.json();
  }
  throw `Unhandled status code ${response.status} for createBehaviorMarker`;
};

export const updateBehaviorResource = async (behavior) => {
  const url = BEHAVIORMARKERS_API_BASE_ROUTE + '/' + behavior.behaviorId + '/resources';
  const response = await authFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(behavior),
  });

  if (response.status === 201 || response.status === 200) {
    return await response.json();
  }

  if (response.status === 204) {
    return {};
  }
  throw `Unhandled status code ${response.status} for updateBehaviorResource`;
};

export const reorderBehavior = async (behaviorMarkerId, sort) => {
  const url = BEHAVIORMARKERS_API_BASE_ROUTE + '/' + behaviorMarkerId + '/sort';

  const response = await authFetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({sort}),
  });

  if (response.status === 200) {
    return await response.json();
  }

  throw `Unhandled status code ${response.status} for reorderBehavior`;
};

export const deleteBehavior = async (behaviorMarkerId) => {
  const url = BEHAVIORMARKERS_API_BASE_ROUTE + '/' + behaviorMarkerId

  const response = await authFetch(url, {
    method: 'DELETE'
  });

  if(!response.ok) {
    throw response;
  }

  return {}
};


