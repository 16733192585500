const { Drawer } = require('@progress/kendo-react-layout');

const DrawerItemAdapter = (props) => {
  return <div style={{ padding: '12px 16px' }}>{props.children}</div>;
};

const RightDrawer = (props) => {
  return (
    <Drawer
      expanded={props.expanded}
      mode="overlay"
      position="end"
      mini={false}
      width={400}
      items={[
        {
          children: props.children,
        },
      ]}
      item={DrawerItemAdapter}
    />
  );
};

export default RightDrawer;