import { ADD_OFFICE, UPDATE_OFFICE, REPLACE_OFFICES } from 'actions/office-grid-actions';

const officeGridReducer = (offices = [], action) => {
  switch (action.type) {
    case REPLACE_OFFICES:
      return action.offices;
    case ADD_OFFICE:
      return [...offices, action.office];
    case UPDATE_OFFICE:
      const officesCopy = [...offices];
      const modifiedOfficeIndex = officesCopy.findIndex(
        (office) => office.officeId === action.officeId
      );
      console.log('modifiedOfficeIndex: ' + modifiedOfficeIndex);
      officesCopy[modifiedOfficeIndex] = {
        ...officesCopy[modifiedOfficeIndex],
        ...action.officeUpdateData,
      };
      return officesCopy;
    default:
      return offices;
  }
};

export default officeGridReducer;
