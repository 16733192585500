import React, { useState, useEffect, useCallback } from "react";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ROUTES } from 'routes';
import { useHistory } from "react-router-dom";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {StackLayout } from '@progress/kendo-react-layout';
import LoadingPanel from 'components/shared/LoadingPanel';
import { DropDownList  } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import PageTitle from 'components/layout/PageTitle';
import CRUDPage from 'components/shared/CRUDPage';

import useNotifications from 'hooks/use-notifications';
import {
    createSuccessNotification,
    createErrorNotification,
  } from 'utils/notifications-creator';

import { getInviteesByProjectId, resetInvitation } from "api/invitees";
//import StatusGridColumn from "components/assessment/StatusGridColumn";


const EditCommandCell = (props) => {
    return (<td><Button themeColor="tertiary" disabled={props.disabled} onClick={props.onClick} size={props.size}>{props.text}</Button></td>)
 };

 const TickCell = (props) => {
  return (
    <td>
      
      {props.value && <span className="k-icon k-i-check"></span>}
    </td>
  );
};

const initialSort = [
    {
        field: "lastEmailSentDate",
        dir: "desc",
    },
];

const InviteesPage = (props) => {
    const history = useHistory();
    const [state, setState] = React.useState({
        globalFilter: "",
        projectInvitationLink:""
        //assessmentType:{id:''}
    })
    const [refresh, setRefresh] = React.useState(0);
    const [loading, setLoading] = useState(true);
    
    const [visible, setVisible] = React.useState(false);

    //const [assessmentTypes, setAssessmentTypes] = useState([{id:'', label:'All'},{id:'S', label:'Self'},{id:'F', label:'Feedback'}]);

    const notifications = useNotifications();

    const onBackButtonClick = (e) => {
        history.push(ROUTES.PROJECTS.EDIT.replace(':projectId', props.match.params.projectId));
    }

    const onGetLikButtonClick = async (e) => {
        console.log(e.personId)

        //console.log('Reset clicked on assessment "' + e.projectId + '"');
        const actionConfirmed = window.confirm(
          `Are you sure you want to reset the invitation link?`
        );
        if (actionConfirmed) {
          setLoading(true);
          try {
            const data = await resetInvitation(props.match.params.projectId, e.personId);
            console.log('projectInvitationLink',data)


            setState({
              ...state,
              projectInvitationLink: data.projectInvitationLink
          });

            window.navigator.clipboard.writeText(data.projectInvitationLink)


            setVisible(true);


            notifications.push(
              createSuccessNotification(
                `The invitation link has been reset successfully!`
              )
            );
            //setRefresh(refresh+1)
          } catch (errorResponse) {
            notifications.push(
              createErrorNotification(
                `We're sorry! We couldn't reset the invitation link". Server response: ${errorResponse.status}`
              )
            );
          }
          setLoading(false);
        }
      };

    const handleChange = (evt) => {
        const value = evt.value;
        setState({
            ...state,
            [evt.target.props.name]: value
        });

    }

    let [dataResult, setDataResult] = useState([])


    useEffect(() => {
        fetchData()
      }, [refresh])


      const fetchData = useCallback(async () => {
        console.log('fetching data...');
        setLoading(true);
        try {
            const data = await getInviteesByProjectId(props.match.params.projectId);
            setDataResult(data)
        } catch (err) {
          notifications.push(createErrorNotification(err.message));
        } finally {
          setLoading(false);
        }
      }, [notifications, refresh]);


      
      /*const handleChangeAssessmentTypeFilter = (event) => {
        setState({
            ...state,
            assessmentType: event.target.value,
        });

        console.log(event.target.value)
    };*/

    const customFilterData = () => {
        if(dataResult){
            return dataResult?.filter(o => {
                return (
                
                (o.firstName.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1) 
                  ||
                (o.lastName.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1)
                  ||
                (o.email.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1) 

                    /*&&
                    (state.assessmentType?.id == '' || state.assessmentType?.id == o.assessmentType)*/

            )
            })
        }else{
            return []
        }
        
    }

    const tableFilters = <div className="form-filter">
        <Field name="globalFilter" label="Search" component={Input} onChange={handleChange}/>
    
        {/*<DropDownList
                    data={assessmentTypes}
                    textField="label"
                    dataItemKey="id"
                    value={state.assessmentType}
                    onChange={handleChangeAssessmentTypeFilter}
                    label="Assessment type"
                    name="Type"
                    

  />*/}
    </div>

    const customInfo = <div></div>

    const backtoproj = <div style={{float:'right'}}><Button fillMode="flat" icon="arrow-chevron-left" onClick={onBackButtonClick}>Back to project</Button></div>


  const toggleDialog = () => {
    setVisible(!visible);
  };

    return (
        <>
            <section className="assessmentPage">
                {loading && <LoadingPanel/>}
                <StackLayout orientation="vertical" gap="1em">
                    <PageTitle text="Atrain Invitees" customitems={backtoproj}/>


                    {visible && (
        <Dialog title={"Invitation link created"} onClose={toggleDialog}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            The invitation link has been reset successfully! Here's the link:<br/>
            <span style={{ maxWidth: 380, overflow: 'auto', wordBreak: "break-all", fontFamily: "monospace" }}>{state.projectInvitationLink}</span>
          </p>
        </Dialog>
      )}


                    <CRUDPage
                        dataresult={customFilterData()}
                        tableFilters={tableFilters}
                        initialSort={initialSort}
                        onRowClick={(e)=>{}}
                        showAddButton={false}
                        showRefreshButton={false}
                        custominfo={customInfo}
                    >

{/*
  "personId": int,
  "firstName": string,
  "lastName": string,
  "email": string,
  "isRater": bool,
  "isFocusPerson": bool,
  "lastEmailSentDate": datetimeoffset -> "2022-12-13T09:42:38.733Z",
  "isRegistrationCompleted": bool
*/
}


                        <GridColumn field="personId" title="ID" width={140} />
                        <GridColumn field="lastEmailSentDate" title="Email sent date" format="{0:d}"  width="155"  />
                        <GridColumn field="firstName" title="Name" />
                        <GridColumn field="lastName" title="Surname" />
                        <GridColumn field="email" width={350} title="Email" />
                        
                        
                        {/*<GridColumn field="sessionStatusName" title="Session status"  cell={(props) => <StatusGridColumn props={props}/>} width="150" />*/}

                        {<GridColumn title="Rater" width={80} cell={(props) => <TickCell value={props.dataItem.isRater} props={props} />} />}

                        {<GridColumn title="Focus" width={90} cell={(props) => <TickCell value={props.dataItem.isFocusPerson} props={props} />} />}
                        
                        {<GridColumn title="Registr. complete" width={150} cell={(props) => <TickCell value={props.dataItem.isRegistrationCompleted} props={props} />} />}
                        
                        {<GridColumn cell={(props) => <EditCommandCell text={'Reset invitation link'} onClick={()=>{onGetLikButtonClick(props.dataItem)}}  size="small"  props={props} />} width="240" />}

                        


                    </CRUDPage>
                   
                    


                </StackLayout>
            </section>
        </>
    );
};

export default InviteesPage;