import {
  DropDownButton,
  DropDownButtonItem,
} from '@progress/kendo-react-buttons';
import useNotifications from 'hooks/use-notifications';
import { createErrorNotification, createSuccessNotification } from 'utils/notifications-creator';

const DeleteActionGridColumn = (props) => {
  const notifications = useNotifications();
  const isDeleteAvailable = props.isDeleteAvailable; // delete available only in draft status

  const itemClickHandler = (e) => e.item.onClick?.();


  const onDeleteHandler = async (dataItem) => {
        
    const confirm = window.confirm(
        'Are you sure you want to delete this item?'
      );
      if (confirm) {
        const p = props

        try {
          await props.deleteFunction(dataItem[props.idKey])
          props.onDeleted(dataItem)
    
        notifications.push(
            createSuccessNotification('Item delete successfully.')
        );

        } catch (err) {
        notifications.push(
            createErrorNotification('Something was wrong deleting the item.')
        );
        } finally {
        
        }
      }

};


  return (
    <td style={{ textAlign: 'right' }}>
      {isDeleteAvailable && <DropDownButton
        icon="more-vertical"
        fillMode="flat"
        onItemClick={itemClickHandler}
        popupSettings={{ anchorAlign: { horizontal: 'left', vertical: 'bottom' }, popupAlign: { horizontal: 'right', vertical: 'bottom' } }}
      >
      
        <DropDownButtonItem
          text="Delete"
          icon="delete"
          disabled={!isDeleteAvailable}
          onClick={onDeleteHandler.bind(null, props.dataItem)}
        />
       
      </DropDownButton>}
    </td>
  );
};

export default DeleteActionGridColumn;
