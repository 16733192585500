import React, { useState, useEffect } from "react";
import CustomDrawer from "components/layout/CustomDrawer";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { ROUTES } from 'routes';
import useQuery from 'hooks/use-query';
import { createCompetency } from "api/competency";
import CRUDPage from 'components/shared/CRUDPage';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { LANGUAGES } from './../../../settings/languages';

import useNotifications from 'hooks/use-notifications';
import {
    createSuccessNotification,
    createErrorNotification,
  } from 'utils/notifications-creator';


import CustomField from 'components/shared/forms/CustomField';
import CustomInput from 'components/shared/forms/CustomInput';
import {validateMaxLength, validateRequiredString } from 'utils/validations';
import HtmlInput from 'components/shared/forms/HtmlInput';
  

const validateRequired = (value) => {
    return (
      validateRequiredString(value) || validateMaxLength(value, 200)
    );
  };

const formFragment = LANGUAGES.map(l => {
    return {
        cultureCode:l.cultureCode,
        name:'',
        negativeName:'',
        positiveName:'',
        description:'',
    }
})

const CompetencyCreate = (props) => {
    const query = useQuery()
    const history = useHistory();
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(true);
    const [drawerIsLoading, setDrawerIsLoading] = React.useState(false);
    const notifications = useNotifications();

    const [detail, setDetail] = React.useState({
        competencyModelId:null,
        sort: 0,
        localization:formFragment
      });

    const onCloseDrawer = () => {
        setDrawerIsOpen(false)
        history.push(ROUTES.COMPETENCY_MODEL.EDIT.replace(':competencyModelId', props.match.params.competencyModelId));
    }
   
    const handleSubmit = async (e) => {
        const position =  query.get('pos') || 1

        const name=  e.localization.map(l => {
            return {
                "cultureCode": l.cultureCode,
                "resourceValue": l.name
            }
        })

        const negativeName=  e.localization.map(l => {
            return {
                "cultureCode": l.cultureCode,
                "resourceValue": l.negativeName
            }
        })

        const positiveName=  e.localization.map(l => {
            return {
                "cultureCode": l.cultureCode,
                "resourceValue": l.positiveName
            }
        })
        
        const description=  e.localization.map(l => {
            return {
                "cultureCode": l.cultureCode,
                "resourceValue": l.description
            }
        })

        const data = {
            competencyModelId: props.match.params.competencyModelId,
            name,
            negativeName,
            positiveName,
            description,
            sort:position
        }

        const p = props
        try {
        await createCompetency(data)

        notifications.push(
            createSuccessNotification('Item updated successfully.')
        );
        p.onChange()
        history.push(ROUTES.COMPETENCY.BASE.replace(':competencyModelId',props.match.params.competencyModelId));
        } catch (err) {
        notifications.push(
            createErrorNotification('Something was wrong updating the item.')
        );
        } finally {
        setDrawerIsLoading(false)
        }

    };


    const [selected, setSelected] = React.useState(0);

    const handleSelect = (e) => {
        setSelected(e.selected);
    };


    return (<>
        <CustomDrawer visible={drawerIsOpen} loading={drawerIsLoading} onClose={onCloseDrawer} title={'COMPETENCY CREATE'} size="">

            {!drawerIsLoading && <div>
                <Form
                    onSubmit={(e) => handleSubmit(e)}
                    initialValues={detail}
                    render={(formRenderProps) => (
                        <FormElement>


                            <TabStrip selected={selected} onSelect={handleSelect} className="fullWidthTab">

                                {detail.localization.map((item, i) => <TabStripTab title={item.cultureCode} key={i}>

                                    <div>
                                        <div className="mb-3">
                                            <CustomField name={"localization[" + i + "].name"} label="Name *" component={HtmlInput}  validator={validateRequired} />
                                        </div>

                                        <div className="mb-3">
                                            <CustomField name={"localization[" + i + "].negativeName"} label="Negative name *" component={HtmlInput} validator={validateRequired} />
                                        </div>

                                        <div className="mb-3">
                                            <CustomField name={"localization[" + i + "].positiveName"} label="Positive name *" component={HtmlInput}  validator={validateRequired} />
                                        </div>

                                        <div className="mb-3">
                                            <CustomField name={"localization[" + i + "].description"} label="Description *" component={HtmlInput}  validator={validateRequired} />
                                        </div>

                                    </div>

                                </TabStripTab>)}


                            </TabStrip>


                            <button
                                type={"submit"}
                                className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-success k-rounded-md"
                                disabled={!formRenderProps.allowSubmit}
                            >
                                CREATE
                            </button>

                        </FormElement>

                    )} />

            </div>}

        </CustomDrawer>
    </>

    );
};

export default CompetencyCreate;